import React, { useState } from "react";
import { Link } from "react-router-dom";

const FinanceNavBar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <nav className="bg-black bg-opacity-80 p-4 shadow-lg font-play">
      <div className="container mx-auto flex justify-between items-center">
        <h1 className="text-2xl font-extrabold text-[#FFD700]">PaySphere</h1>
        <div className="hidden md:flex space-x-8">
          <Link to="/finance" className="text-white hover:text-purple-600 transition">
            Home
          </Link>
          <Link to="/finance/features" className="text-white hover:text-purple-600 transition">
            Features
          </Link>
          <Link to="/finance/pricing" className="text-white hover:text-purple-600 transition">
            Pricing
          </Link>
          <Link to="/finance/contact" className="text-white hover:text-purple-600 transition">
            Contact
          </Link>
        </div>
        {/* Hamburger Icon */}
        <div className="md:hidden">
          <button
            onClick={toggleMenu}
            className="text-white focus:outline-none"
          >
            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16m-7 6h7" />
            </svg>
          </button>
        </div>
      </div>
      {/* Mobile Menu */}
      {isOpen && (
        <div className="md:hidden flex flex-col items-center space-y-4 bg-black bg-opacity-90 p-4">
          <Link to="/finance" onClick={toggleMenu} className="text-white hover:text-purple-600 transition">
            Home
          </Link>
          <Link to="/finance/features" onClick={toggleMenu} className="text-white hover:text-purple-600 transition">
            Features
          </Link>
          <Link to="/finance/pricing" onClick={toggleMenu} className="text-white hover:text-purple-600 transition">
            Pricing
          </Link>
          <Link to="/finance/contact" onClick={toggleMenu} className="text-white hover:text-purple-600 transition">
            Contact
          </Link>
        </div>
      )}
    </nav>
  );
};

export default FinanceNavBar;
