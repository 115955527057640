import React from "react";
import LogisticsNavBar from "./LogisticsNavBar";

const LogisticsContact = () => {
  return (
    <div className="bg-gray-900 text-gray-200 font-ubuntu">
      <LogisticsNavBar/>
      {/* Header */}
      <header className="bg-gray-800 text-center py-10">
        <h1 className="text-5xl font-bold text-orange-600" style={{ fontFamily: 'Lobster, sans-serif' }}>Contact Us</h1>
        <p className="mt-4 text-gray-200 max-w-2xl mx-auto">
          Whether you have a question, need support, or want to discuss your logistics needs, our team at TruckForce is here to help. Reach out to us directly or contact one of our team members below.
        </p>
      </header>

      {/* Contact Information */}
      <section className="py-16 px-8 md:px-16 bg-gray-900">
        <h2 className="text-4xl font-bold text-center text-orange-600 mb-12" style={{ fontFamily: 'Lobster, sans-serif' }}>Our Team</h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {/* CEO */}
          <div className="bg-gray-800 p-8 rounded-lg shadow-lg">
            <h3 className="text-2xl font-bold text-orange-600 mb-4">Samuel Johnson</h3>
            <p className="text-gray-200 mb-4">Chief Executive Officer</p>
            <p className="text-gray-200 mb-4">
              Samuel brings over 20 years of experience in logistics and supply chain management to TruckForce. His vision for efficient and sustainable logistics has propelled our company to become a leader in the industry.
            </p>
            <p className="text-gray-200">
              <strong>Email:</strong> <a href="mailto:samuel.johnson@truckforce.com" className="text-orange-600 hover:underline">samuel.johnson@truckforce.com</a><br />
              <strong>Phone:</strong> <a href="tel:+1234567890" className="text-orange-600 hover:underline">+1 (234) 567-890</a>
            </p>
          </div>

          {/* Head of Operations */}
          <div className="bg-gray-800 p-8 rounded-lg shadow-lg">
            <h3 className="text-2xl font-bold text-orange-600 mb-4">Elena Rodriguez</h3>
            <p className="text-gray-200 mb-4">Head of Operations</p>
            <p className="text-gray-200 mb-4">
              Elena oversees our day-to-day logistics operations, ensuring that every shipment is handled with precision and efficiency. Her expertise in optimizing processes has been invaluable in achieving on-time deliveries for our clients.
            </p>
            <p className="text-gray-200">
              <strong>Email:</strong> <a href="mailto:elena.rodriguez@truckforce.com" className="text-orange-600 hover:underline">elena.rodriguez@truckforce.com</a><br />
              <strong>Phone:</strong> <a href="tel:+1234567891" className="text-orange-600 hover:underline">+1 (234) 567-891</a>
            </p>
          </div>

          {/* Customer Support */}
          <div className="bg-gray-800 p-8 rounded-lg shadow-lg">
            <h3 className="text-2xl font-bold text-orange-600 mb-4">Michael Chen</h3>
            <p className="text-gray-200 mb-4">Customer Support Manager</p>
            <p className="text-gray-200 mb-4">
              Michael leads our customer support team, dedicated to assisting clients with inquiries, shipment tracking, and any concerns they may have. His focus on customer satisfaction ensures a seamless experience for every TruckForce client.
            </p>
            <p className="text-gray-200">
              <strong>Email:</strong> <a href="mailto:michael.chen@truckforce.com" className="text-orange-600 hover:underline">michael.chen@truckforce.com</a><br />
              <strong>Phone:</strong> <a href="tel:+1234567892" className="text-orange-600 hover:underline">+1 (234) 567-892</a>
            </p>
          </div>
        </div>
      </section>

      {/* Contact Form */}
      <section className="py-16 px-8 md:px-16 bg-gray-900">
        <h2 className="text-4xl font-bold text-center text-orange-600 mb-12" style={{ fontFamily: 'Lobster, sans-serif' }}>Get in Touch</h2>
        <form className="max-w-2xl mx-auto bg-gray-800 p-8 rounded-lg shadow-lg space-y-6">
          <div>
            <label htmlFor="name" className="block text-lg font-medium mb-2">Full Name</label>
            <input
              type="text"
              id="name"
              className="w-full p-3 bg-gray-900 border border-gray-700 rounded text-gray-200 focus:border-orange-600"
              placeholder="Your full name"
            />
          </div>
          <div>
            <label htmlFor="email" className="block text-lg font-medium mb-2">Email Address</label>
            <input
              type="email"
              id="email"
              className="w-full p-3 bg-gray-900 border border-gray-700 rounded text-gray-200 focus:border-orange-600"
              placeholder="Your email address"
            />
          </div>
          <div>
            <label htmlFor="message" className="block text-lg font-medium mb-2">Message</label>
            <textarea
              id="message"
              rows="5"
              className="w-full p-3 bg-gray-900 border border-gray-700 rounded text-gray-200 focus:border-orange-600"
              placeholder="Write your message here..."
            ></textarea>
          </div>
          <button type="submit" className="w-full bg-orange-600 text-gray-200 py-3 rounded-lg font-medium hover:bg-orange-700 transition">
            Send Message
          </button>
        </form>
      </section>

    
    </div>
  );
};

export default LogisticsContact;
