// src/pages/Education/TopicsPage.js

import React from "react";
import careerImage from "../../assets/education/b1.webp";
import techImage from "../../assets/education/b2.webp";
import studyImage from "../../assets/education/b3.webp";
import healthImage from "../../assets/education/b4.webp";
import financeImage from "../../assets/education/b5.webp";
import productivityImage from "../../assets/education/b6.webp";
import EducationNavBar from "./EducationNavBar";

const topics = [
  {
    title: "Career Advice",
    image: careerImage,
    description: "From resume crafting to networking strategies, explore guidance that can help accelerate your professional journey. Whether you’re starting or advancing, these insights will help you navigate today’s complex job market.",
  },
  {
    title: "Tech Trends",
    image: techImage,
    description: "Stay updated with the latest in technology, from artificial intelligence breakthroughs to innovations in blockchain. Dive deep into how these trends are reshaping industries and our everyday lives.",
  },
  {
    title: "Study Hacks",
    image: studyImage,
    description: "Learn the secrets of effective studying, from memory techniques to time management skills. These tips are designed to make your learning journey smoother and more rewarding.",
  },
  {
    title: "Health & Wellness",
    image: healthImage,
    description: "Explore ways to achieve a balanced lifestyle. From nutrition advice to mental wellness, find valuable tips to help you maintain your health and well-being in a fast-paced world.",
  },
  {
    title: "Personal Finance",
    image: financeImage,
    description: "Discover essential practices for managing finances wisely. Learn about budgeting, investing, and saving strategies that can help you achieve financial security and peace of mind.",
  },
  {
    title: "Productivity",
    image: productivityImage,
    description: "Master the art of productivity with tools, techniques, and mindset shifts. Whether at work or home, these insights can help you make the most of your time and energy.",
  },
];

const EducationTopics = () => {
  return (
    <div className="bg-gray-800 text-slate-200 font-source-sans">
        <EducationNavBar/>
      <h1 className="text-4xl font-merriweather text-center py-10 mb-4 text-amber-500">Topics of Interest</h1>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
        {topics.map((topic, index) => (
          <div key={index} className="bg-slate-900 border border-gray-700 rounded-lg shadow-lg p-6 flex flex-col items-center text-center">
            <img src={topic.image} alt={topic.title} className="w-full h-48 object-cover rounded mb-4" />
            <h2 className="text-2xl font-merriweather mb-2 text-amber-500">{topic.title}</h2>
            <p className="text-gray-300">{topic.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default EducationTopics;
