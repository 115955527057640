import React from 'react';
import FoodNavBar from './FoodNavBar';
import beerVideo from '../../assets/food/beer25.mp4';
import heroImage from '../../assets/food/bbc2.webp';
import tileImage from '../../assets/food/tile.webp';

import hoppyAdventureImage from '../../assets/food/Hoppy.webp';
import plumPorterImage from '../../assets/food/Plum.webp';
import amberDelightImage from '../../assets/food/Amber.webp';
import goldenHoneyImage from '../../assets/food/Honey.webp';
import spicySaisonImage from '../../assets/food/Spicy.webp';
import chocoStoutImage from '../../assets/food/Choco.webp';

const beers = [
  { name: "Hoppy Adventure", flavorProfile: "Citrus and Pine", alcoholContent: "5.5% ABV", image: hoppyAdventureImage },
  { name: "Plum Porter", flavorProfile: "Rich and Fruity", alcoholContent: "6.2% ABV", image: plumPorterImage },
  { name: "Amber Delight", flavorProfile: "Toasty and Sweet", alcoholContent: "4.8% ABV", image: amberDelightImage },
  { name: "Golden Honey", flavorProfile: "Floral and Light", alcoholContent: "4.2% ABV", image: goldenHoneyImage },
  { name: "Spicy Saison", flavorProfile: "Peppery and Bright", alcoholContent: "6.0% ABV", image: spicySaisonImage },
  { name: "Choco Stout", flavorProfile: "Chocolate and Coffee", alcoholContent: "7.0% ABV", image: chocoStoutImage },
];

const FoodHome = () => {
  return (
    <div className="bg-[#F5F1ED] text-gray-900 font-sans">
      <FoodNavBar />

      {/* Hero Section with Video */}
      <section className="relative h-auto md:h-screen flex flex-col md:flex-row overflow-hidden">
        <div className="w-full md:w-1/2 flex items-center justify-center">
          <img src={heroImage} alt="Craft Beer" className="w-full h-auto md:h-full object-cover rounded-lg shadow-lg" />
        </div>
        <div className="w-full md:w-1/2 flex items-center justify-center relative">
          <video className="w-full h-auto md:h-full object-cover rounded-lg shadow-lg" autoPlay loop muted>
            <source src={beerVideo} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
          {/* Text Overlay */}
          <div className="absolute inset-0 flex flex-col items-center justify-center p-4 md:p-8">
            <h1 className="text-3xl sm:text-4xl md:text-5xl font-extrabold text-[#D72638] mb-4 text-center" style={{ fontFamily: 'Pacifico, cursive' }}>
              Crafted to Perfection
            </h1>
            <p className="text-base sm:text-lg text-white mb-6 text-center">Experience bold flavors and rich heritage in every sip.</p>
            <a href="/food/about" className="bg-[#F6C90E] text-black px-6 py-2 md:px-8 md:py-3 rounded-full font-bold hover:bg-[#1687A7] transition transform hover:-translate-y-1">
              Discover More
            </a>
          </div>
        </div>
      </section>

      {/* Our Beers Brewed In House */}
      <section className="py-20 px-6 sm:px-10" style={{ backgroundImage: `url(${tileImage})`, backgroundRepeat: 'repeat' }}>
        <div className="text-center mb-12">
          <div className="inline-block bg-[#F6C90E] p-4 rounded-lg shadow-lg">
            <h2 className="text-3xl sm:text-4xl font-bold text-[#502419]" style={{ fontFamily: 'Pacifico, cursive' }}>Our Beers Brewed In House</h2>
          </div>
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-6 sm:gap-10">
          {beers.map((beer, index) => (
            <div key={index} className="flex flex-col sm:flex-row items-center bg-[#D3D3D3] rounded-lg shadow-lg p-4 sm:p-6 w-full max-w-md mx-auto">
              <img src={beer.image} alt={beer.name} className="w-32 h-32 sm:w-40 sm:h-40 object-cover rounded-md mb-4 sm:mb-0 sm:mr-4" />
              <div className="flex flex-col justify-center text-center sm:text-left">
                <h3 className="text-2xl sm:text-3xl font-semibold text-[#502419] mb-1">{beer.name}</h3>
                <p className="text-gray-800 text-sm sm:text-lg"><strong>Flavor Profile:</strong> {beer.flavorProfile}</p>
                <p className="text-gray-800 text-sm sm:text-lg"><strong>Alcohol Content:</strong> {beer.alcoholContent}</p>
              </div>
            </div>
          ))}
        </div>
      </section>
    </div>
  );
};

export default FoodHome;
