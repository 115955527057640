import React from 'react';
import EducationNavBar from './EducationNavBar';

const EducationSample = () => {
  return (
    <div className="bg-gray-800 text-slate-200 font-play">
      <EducationNavBar />

      {/* Title Section */}
      <section className="py-20 text-center bg-slate-900">
        <h1 className="text-3xl sm:text-4xl md:text-5xl lg:text-6xl font-merriweather text-amber-500 mb-4">
          The Comprehensive Guide to Bitcoin
        </h1>
        <p className="text-lg sm:text-xl md:text-2xl font-source-sans text-gray-400">
          Everything you need to know about the world’s most popular cryptocurrency
        </p>
      </section>

      {/* Article Content */}
      <article className="py-16 px-8 md:px-16 bg-gray-800">
        <section className="mb-12">
          <h2 className="text-4xl font-merriweather text-white mb-6">1. What is Bitcoin?</h2>
          <p className="text-gray-400 font-source-sans text-lg leading-relaxed">
            Bitcoin, introduced in 2009 by an anonymous figure known as Satoshi Nakamoto, is a decentralized digital currency. 
            Operating on a peer-to-peer network, it allows users to transfer funds without the need for intermediaries such as banks. 
            This innovative currency is built on blockchain technology, a transparent ledger that records every transaction in a 
            distributed database, ensuring both security and transparency. Bitcoin has a fixed supply of 21 million coins, which 
            contrasts sharply with traditional fiat currencies that central banks can print indefinitely.
          </p>
        </section>

        <section className="mb-12">
          <h2 className="text-4xl font-merriweather text-white mb-6">2. The Technology Behind Bitcoin: Blockchain</h2>
          <p className="text-gray-400 font-source-sans text-lg leading-relaxed">
            Blockchain, Bitcoin's underlying technology, is a public ledger that records all transactions in blocks. Each block is 
            cryptographically linked to the previous one, creating a chain of immutable data. This structure makes the blockchain 
            extremely secure and resistant to tampering, as altering one block would require changing every subsequent block. 
            Miners, who validate transactions by solving complex mathematical problems, add new blocks to the chain, securing 
            the network through a consensus mechanism known as Proof-of-Work (PoW).
          </p>
        </section>

        <section className="mb-12">
          <h2 className="text-4xl font-merriweather text-white mb-6">3. How Bitcoin Transactions Work</h2>
          <p className="text-gray-400 font-source-sans text-lg leading-relaxed">
            A Bitcoin transaction begins when a sender digitally signs a transaction request and broadcasts it to the network. 
            Miners pick up this transaction, verify it, and include it in a new block once validated. The block then joins the 
            blockchain, becoming part of Bitcoin's transparent transaction history. Transactions are irreversible, meaning once 
            confirmed, they cannot be undone or canceled, providing security but also requiring caution in usage.
          </p>
        </section>

        <section className="mb-12">
          <h2 className="text-4xl font-merriweather text-white mb-6">4. Mining: The Backbone of Bitcoin’s Security</h2>
          <p className="text-gray-400 font-source-sans text-lg leading-relaxed">
            Mining is the process by which new bitcoins are introduced and transactions are verified. Miners use computational 
            power to solve mathematical puzzles that secure the network. Every successful puzzle-solving adds a new block to the 
            blockchain and rewards the miner with newly minted bitcoins. However, mining requires significant energy, leading to 
            debates about its environmental impact and the future sustainability of Proof-of-Work.
          </p>
        </section>

        <section className="mb-12">
          <h2 className="text-4xl font-merriweather text-white mb-6">5. Bitcoin’s Price Volatility</h2>
          <p className="text-gray-400 font-source-sans text-lg leading-relaxed">
            Bitcoin is known for its price volatility, which stems from a range of factors including market speculation, 
            regulatory announcements, and overall market sentiment. Despite its fluctuations, Bitcoin has witnessed substantial 
            growth since its inception, attracting both individual and institutional investors. This volatility offers potential 
            rewards but comes with risks, making Bitcoin an investment that requires thorough research and risk management.
          </p>
        </section>

        <section className="mb-12">
          <h2 className="text-4xl font-merriweather text-white mb-6">6. Bitcoin vs. Traditional Currencies</h2>
          <p className="text-gray-400 font-source-sans text-lg leading-relaxed">
            Unlike traditional fiat currencies, which are controlled by central banks, Bitcoin operates without any central 
            authority. Its supply is fixed at 21 million coins, providing a deflationary model compared to inflation-prone fiat. 
            This decentralized nature and fixed supply have led many to consider Bitcoin as a “digital gold,” a hedge against 
            inflation, and a revolutionary way to store value independently of government control.
          </p>
        </section>

        <section className="mb-12">
          <h2 className="text-4xl font-merriweather text-white mb-6">7. The Future of Bitcoin: Challenges and Opportunities</h2>
          <p className="text-gray-400 font-source-sans text-lg leading-relaxed">
            As Bitcoin matures, it faces both challenges and opportunities. Regulatory scrutiny is increasing worldwide, with 
            governments contemplating policies to monitor and control cryptocurrency usage. At the same time, Bitcoin adoption 
            is growing, with more businesses and financial institutions integrating it into their services. The rise of Layer 2 
            solutions, such as the Lightning Network, promises to make Bitcoin transactions faster and cheaper, while innovations 
            in green energy solutions aim to address environmental concerns.
          </p>
        </section>

        <section className="mb-12">
          <h2 className="text-4xl font-merriweather text-white mb-6">8. Bitcoin as an Investment</h2>
          <p className="text-gray-400 font-source-sans text-lg leading-relaxed">
            Bitcoin is increasingly seen as a valuable asset, attracting investors from around the world. However, it’s important 
            to understand the risks associated with Bitcoin investing, from price volatility to regulatory risks. While some view 
            Bitcoin as a hedge against traditional financial systems, others invest for speculative gains. As with any asset, 
            a clear strategy and risk tolerance are essential when investing in Bitcoin.
          </p>
        </section>
      </article>

      {/* Footer */}
      <footer className="py-8 bg-slate-900 text-white text-center">
        <div className="container mx-auto">
          <h3 className="text-lg font-semibold font-merriweather mb-4">Want to Learn More?</h3>
          <p className="text-sm font-source-sans text-gray-400 mb-4">Stay updated with the latest insights and trends in Bitcoin and blockchain technology.</p>
          <button className="bg-amber-500 text-slate-900 px-6 py-3 rounded-lg font-lato font-medium hover:bg-amber-600 transition">
            Subscribe Now
          </button>
        </div>
      </footer>
    </div>
  );
};

export default EducationSample;
