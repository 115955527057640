import React from 'react';
import FurnitureNavBar from './FurnitureNavBar';

const FurnitureContact = () => {
  return (
    <div className="bg-gray-100 text-gray-800 font-sans" style={{ fontFamily: 'Cormorant Garamond, serif' }}>
        <FurnitureNavBar/>
      
      {/* Hero Section */}
      <section className="bg-gray-800 text-gray-100 p-12 text-center">
        <h1 className="text-4xl font-bold text-yellow-400 mb-4">Get In Touch</h1>
        <p className="text-lg max-w-3xl mx-auto">
          We’d love to discuss your next project. Whether it’s custom furniture, restoration, or a unique idea, TimberWorks is here to help bring your vision to life.
        </p>
      </section>

      {/* Contact Information Section */}
      <section className="py-16 px-8 md:px-16 bg-gray-100">
        <h2 className="text-3xl font-bold text-center mb-6 text-gray-800">Contact Information</h2>
        <p className="text-lg text-gray-800 text-center max-w-3xl mx-auto mb-12">
          Feel free to reach out to us via phone, email, or drop by our workshop to discuss your ideas in person. We’re here to answer any questions and provide guidance on your custom woodwork project.
        </p>
        
        <div className="grid md:grid-cols-2 gap-12 text-center">
          {/* Phone and Email */}
          <div className="bg-white rounded-lg shadow-lg p-8">
            <h3 className="text-2xl font-semibold text-yellow-600">Phone & Email</h3>
            <p className="text-gray-700 mt-4">Phone: <span className="text-gray-900 font-bold">+1 (555) 123-4567</span></p>
            <p className="text-gray-700 mt-2">Email: <span className="text-gray-900 font-bold">contact@timberworks.com</span></p>
            <p className="text-gray-700 mt-4">Our team is available Monday through Friday, 9am to 5pm.</p>
          </div>

          {/* Address */}
          <div className="bg-white rounded-lg shadow-lg p-8">
            <h3 className="text-2xl font-semibold text-yellow-600">Our Workshop</h3>
            <p className="text-gray-700 mt-4">123 Timber Lane, Artisan District</p>
            <p className="text-gray-700">Woodcraft City, WC 54321</p>
            <p className="text-gray-700 mt-4">Visit our workshop to explore our materials and get a feel for our craftsmanship.</p>
          </div>
        </div>
      </section>

      {/* Contact Form */}
      <section className="py-16 px-8 md:px-16 bg-gray-200">
        <h2 className="text-3xl font-bold text-center text-gray-800 mb-6">Send Us a Message</h2>
        <p className="text-lg text-center max-w-3xl mx-auto text-gray-800 mb-12">
          Have a project in mind? Use the form below to get in touch with us, and we’ll get back to you as soon as possible.
        </p>
        
        <form className="max-w-3xl mx-auto bg-white p-8 rounded-lg shadow-lg">
          <div className="grid grid-cols-1 gap-6">
            {/* Name */}
            <div>
              <label htmlFor="name" className="block text-lg text-gray-800 font-semibold">Name</label>
              <input
                type="text"
                id="name"
                className="w-full px-4 py-2 mt-2 border rounded-lg text-gray-800 focus:outline-none focus:ring-2 focus:ring-yellow-400"
                placeholder="Your Name"
              />
            </div>

            {/* Email */}
            <div>
              <label htmlFor="email" className="block text-lg text-gray-800 font-semibold">Email</label>
              <input
                type="email"
                id="email"
                className="w-full px-4 py-2 mt-2 border rounded-lg text-gray-800 focus:outline-none focus:ring-2 focus:ring-yellow-400"
                placeholder="Your Email Address"
              />
            </div>

            {/* Message */}
            <div>
              <label htmlFor="message" className="block text-lg text-gray-800 font-semibold">Message</label>
              <textarea
                id="message"
                rows="4"
                className="w-full px-4 py-2 mt-2 border rounded-lg text-gray-800 focus:outline-none focus:ring-2 focus:ring-yellow-400"
                placeholder="Tell us about your project or ask any questions"
              />
            </div>

            {/* Submit Button */}
            <button
              type="submit"
              className="bg-yellow-400 text-gray-800 font-bold py-3 px-6 rounded-lg hover:bg-yellow-500 transition mt-4"
            >
              Send Message
            </button>
          </div>
        </form>
      </section>

      {/* Location Map Section */}
      

      <section className="mt-16">
      <h2 className="text-3xl font-bold text-center text-gray-800 mb-6">Visit Us</h2>
        <p className="text-lg text-center max-w-3xl mx-auto text-gray-800 mb-12">
          Stop by our workshop to view samples, discuss ideas, or explore our range of materials. Here’s where you can find us:
        </p>
        <iframe
          title="Graf Streetwear Location"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3151.8354345093745!2d144.95373511565353!3d-37.817209742105224!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6ad642af0f11fd81%3A0xf57708c5e5d4d5e6!2sFederation%20Square!5e0!3m2!1sen!2sus!4v1637916375307!5m2!1sen!2sus"
          width="100%"
          height="400"
          className="rounded-lg shadow-lg"
          allowFullScreen=""
          loading="lazy"
        ></iframe>
      </section>
    </div>
  );
};

export default FurnitureContact;
