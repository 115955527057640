import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import LegalNavBar from './LegalNavBar';
import lawImage from '../../assets/legal/law2.jpg';
import "./slick-custom.css";

const LegalHome = () => {
  const [isSmallScreen, setIsSmallScreen] = useState(false);

  useEffect(() => {
    const checkScreenSize = () => setIsSmallScreen(window.innerWidth <= 640);
    checkScreenSize();
    window.addEventListener('resize', checkScreenSize);
    return () => window.removeEventListener('resize', checkScreenSize);
  }, []);

  const testimonials = [
    "Professional, responsive, and truly dedicated. They made me feel confident every step of the way.",
    "Lexis & Anchor LLP provided excellent guidance and support during a challenging time. Highly recommend!",
    "Outstanding service and expertise. I felt valued and well-informed throughout the entire process.",
    "Their attention to detail and commitment to my case was unparalleled. A trustworthy team.",
    "I couldn't have asked for a better legal team. They genuinely cared about my outcome.",
    "Impressive results and clear communication. I felt secure knowing they were handling my case.",
  ];

  const sliderSettings = {
    dots: true,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 2000,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: { slidesToShow: 2 },
      },
      {
        breakpoint: 768,
        settings: { slidesToShow: 1 },
      },
    ],
  };

  return (
    <div className="bg-slate-900 text-gray-200 font-sans">
      <LegalNavBar />

      {/* Hero Section */}
      <section
        className="relative flex items-center justify-start"
        style={{
          height: isSmallScreen ? '100vh' : '100vh',
          backgroundImage: `url(${lawImage})`,
          backgroundPosition: isSmallScreen ? 'left' : 'center',
          backgroundSize: isSmallScreen ? 'auto 100%' : 'cover',
          backgroundRepeat: 'no-repeat',
        }}
      >
        <div className="absolute inset-0 flex flex-col justify-center items-start px-8 pt-4 sm:pt-0 lg:w-2/3 lg:px-24">
          <div className="text-left text-gray-900 sm:text-gray-800 lg:text-gray-700">
            <h1 className="text-3xl sm:text-6xl font-serif font-bold mb-2 sm:mb-4" style={{ letterSpacing: '2px' }}>
              Lexis & Anchor LLP
            </h1>
            <h2 className="text-2xl sm:text-5xl font-serif font-bold mb-2 sm:mb-4 text-gray-700 lg:text-gray-500">
              Protecting Your Rights, Pursuing Justice
            </h2>
            <h3 className="text-xl sm:text-3xl font-serif font-bold mb-4 sm:mb-8 text-gray-600 lg:text-gray-400">
              Experienced Legal Representation Tailored to <br />Your Needs
            </h3>
            <a href="/legal/contact" className="bg-gray-800 text-teal-400 px-6 py-3 rounded-lg font-medium border border-teal-500 hover:bg-gray-700 hover:text-teal-300 transition">
              Schedule a Consultation
            </a>
          </div>
        </div>
      </section>

      {/* About Us Preview */}
      <section className="py-16 px-8 md:px-16 bg-slate-800 text-gray-300">
        <h2 className="text-4xl font-serif font-bold mb-6 text-center text-teal-400">
          About Our Firm
        </h2>
        <p className="text-lg text-center max-w-3xl mx-auto">
          Our team of skilled attorneys is dedicated to providing comprehensive legal services with a focus on personalized solutions. With a proven track record, we stand by our clients every step of the way.
        </p>
      </section>

      {/* Practice Areas */}
      <section className="py-20 px-8 md:px-16 bg-gray-100">
        <h2 className="text-4xl font-serif font-bold mb-12 text-center text-slate-800">
          Our Practice Areas
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {["Family Law", "Corporate Law", "Personal Injury"].map((area, index) => (
            <div key={index} className="bg-white rounded-lg shadow-lg p-6 border-l-4 border-teal-500 hover:bg-gray-200 transition duration-300">
              <h3 className="text-2xl font-serif font-semibold mb-2 text-slate-800">
                {area}
              </h3>
              <p className="text-gray-600">
                Expert guidance and representation in {area.toLowerCase()} matters.
              </p>
            </div>
          ))}
        </div>
      </section>

      {/* Testimonials Carousel */}
      <section className="py-20 px-8 md:px-16 bg-slate-800 text-gray-300">
        <h2 className="text-4xl font-serif font-bold mb-12 text-center text-teal-400">
          What Our Clients Say
        </h2>
        <Slider {...sliderSettings}>
          {testimonials.map((testimonial, index) => (
            <div key={index} className="bg-slate-700 rounded-lg p-8 shadow-lg border-t-4 border-teal-500">
              <p className="italic text-gray-300 text-lg mb-4">"{testimonial}"</p>
              <p className="text-right font-bold text-teal-400">- Client {index + 1}</p>
            </div>
          ))}
        </Slider>
      </section>

      {/* Contact CTA */}
      <section className="py-20 px-8 md:px-16 bg-slate-700 text-white text-center">
        <h2 className="text-4xl font-serif font-bold mb-6 text-gray-100">
          Need Legal Assistance?
        </h2>
        <p className="text-lg mb-8">
          Contact us today for a confidential consultation.
        </p>
        <a href="/legal/contact" className="bg-teal-500 text-white px-8 py-4 rounded-lg font-medium hover:bg-teal-600 transition">
          Get in Touch
        </a>
      </section>
    </div>
  );
};

export default LegalHome;
