import React from "react";
import LegalNavBar from "./LegalNavBar";

const LegalServices = () => {
  return (
    <div className="min-h-screen bg-slate-800 text-slate-200 font-sans">
      <LegalNavBar />

      {/* Header Section */}
      <header className="bg-slate-900 text-white py-12 text-center">
        <h1 className="text-5xl font-serif font-bold text-teal-400">
          Our Legal Services
        </h1>
        <p className="text-lg mt-4 max-w-3xl mx-auto text-slate-300">
          A full spectrum of legal services tailored to meet the specific needs
          of individuals and businesses, delivered with unmatched expertise.
        </p>
      </header>

      {/* Main Content */}
      <main className="py-16 px-8 max-w-screen-lg mx-auto space-y-12">
        {/* Family Law Section */}
        <section className="bg-slate-700 p-10 rounded-lg shadow-md border-l-4 border-teal-400">
          <h2 className="text-3xl font-serif font-bold text-teal-400 mb-4">
            Family Law
          </h2>
          <p className="text-slate-300 mb-6">
            Our experienced team offers compassionate guidance for sensitive
            family matters. Let us help you find stability and clarity during
            life’s transitions.
          </p>
          <ul className="list-disc list-inside space-y-2 text-slate-400">
            <li>Divorce & Separation Agreements</li>
            <li>Child Custody & Support</li>
            <li>Asset Protection & Division</li>
            <li>Adoption & Guardianship</li>
          </ul>
        </section>

        {/* Corporate Law Section */}
        <section className="bg-slate-700 p-10 rounded-lg shadow-md border-l-4 border-teal-400">
          <h2 className="text-3xl font-serif font-bold text-teal-400 mb-4">
            Corporate Law
          </h2>
          <p className="text-slate-300 mb-6">
            Strategic, insightful, and thorough legal support to help your
            business flourish. From formation to transactions, we have you
            covered.
          </p>
          <ul className="list-disc list-inside space-y-2 text-slate-400">
            <li>Business Formation & Structuring</li>
            <li>Contract Drafting & Negotiation</li>
            <li>Corporate Governance & Compliance</li>
            <li>Mergers & Acquisitions</li>
          </ul>
        </section>

        {/* Personal Injury Law Section */}
        <section className="bg-slate-700 p-10 rounded-lg shadow-md border-l-4 border-teal-400">
          <h2 className="text-3xl font-serif font-bold text-teal-400 mb-4">
            Personal Injury
          </h2>
          <p className="text-slate-300 mb-6">
            Pursuing justice for those affected by accidents and injuries. We
            are committed to achieving fair compensation for our clients.
          </p>
          <ul className="list-disc list-inside space-y-2 text-slate-400">
            <li>Automobile & Motorcycle Accidents</li>
            <li>Workplace Injuries</li>
            <li>Medical Malpractice</li>
            <li>Slip & Fall Incidents</li>
          </ul>
        </section>
      </main>

      {/* Call to Action Section */}
      <footer className="bg-slate-900 text-center py-16">
        <h2 className="text-4xl font-serif font-bold text-teal-400 mb-6">
          Ready to Protect Your Rights?
        </h2>
        <p className="text-lg text-slate-300 mb-8 max-w-xl mx-auto">
          Contact our team today for a confidential consultation. We're here to
          provide the guidance and representation you need.
        </p>
        <a href="/legal/contact" className="bg-teal-400 text-slate-900 px-8 py-4 rounded-lg font-medium hover:bg-teal-500 transition">
          Schedule a Consultation
        </a>
      </footer>
    </div>
  );
};

export default LegalServices;
