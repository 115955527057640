import React from "react";

const Footer = () => (
  <footer className="bg-gray-800 text-white p-4 text-center mt-10">
    <p className="text-lg font-semibold">&copy; 2024 gigaDesign</p>
    
    <div className="flex flex-col sm:flex-row justify-center items-center mt-4 space-y-4 sm:space-y-0 sm:space-x-4">
      {/* Back to GigaWebDesign Home Button */}
      <a
        href="/"
        className="bg-gray-600 text-white px-6 py-2 rounded-lg font-lato font-medium hover:bg-gray-500 transition"
      >
        gigaDesign
      </a>

      {/* GigaWorkflow Button (External Site) */}
      <a
        href="https://gigaworkflow.xyz"  // replace with the actual URL
        target="_blank"
        rel="noopener noreferrer"
        className="bg-gray-600 text-white px-6 py-2 rounded-lg font-lato font-medium hover:bg-gray-500 transition"
      >
        gigaWorkflow
      </a>

      {/* Contact Button (Email) */}
      <a
        href="mailto:daniel@gigaworkflow.xyz"  // replace with actual email
        className="bg-gray-600 text-white px-6 py-2 rounded-lg font-lato font-medium hover:bg-gray-500 transition"
      >
        Contact
      </a>
    </div>
  </footer>
);

export default Footer;
