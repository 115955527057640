import React from 'react';
import { motion } from 'framer-motion';
import HealthcareNavBar from './HealthcareNavBar';
import cleaningImage from '../../assets/healthcare/hc1.jpg';
import cosmeticImage from '../../assets/healthcare/hc2.jpg';
import emergencyImage from '../../assets/healthcare/hc3.jpg';
import orthoImage from '../../assets/healthcare/hc5.jpg';
import restorativeImage from '../../assets/healthcare/hc6.jpg';
import pediatricImage from '../../assets/healthcare/hc4.jpg';

const HealthcareServices = () => {
  const serviceVariants = {
    hidden: { opacity: 0, x: -50 },
    visible: { opacity: 1, x: 0 }
  };

  return (
    <div className="bg-gray-50 text-gray-900 font-lora"> {/* Apply Lora font */}
      <HealthcareNavBar />

      {/* Header Section */}
      <section className="py-16 px-8 md:px-16 bg-blue-800 text-white text-center">
        <h1 className="text-4xl font-bold mb-4">Our Dental Services</h1>
        <p className="text-lg max-w-3xl mx-auto">
          Smiles Dental offers a full range of dental services to meet every family’s needs. From routine care to specialized treatments, our dedicated team of professionals is here to ensure your oral health journey is as smooth and comfortable as possible.
        </p>
      </section>

      {/* Services Section */}
      <section className="py-16 px-8 md:px-16 bg-gray-100">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-12">
          {[
            {
              title: "Cleanings & Exams",
              description: "Regular dental cleanings and exams are crucial for maintaining healthy teeth and gums. Our comprehensive cleanings remove plaque and tartar buildup, and our exams help detect any potential issues early.",
              pricing: "Starting at $100",
              img: cleaningImage
            },
            {
              title: "Cosmetic Dentistry",
              description: "Enhance your smile with our cosmetic dentistry options, including teeth whitening, veneers, and bonding. Our treatments are tailored to meet your aesthetic goals, giving you a smile you’ll be proud to show off.",
              pricing: "Consultation: $50, Veneers: $800/tooth, Whitening: $200",
              img: cosmeticImage
            },
            {
              title: "Emergency Care",
              description: "Dental emergencies happen when you least expect them. Our team is available to provide immediate relief for toothaches, broken teeth, and other urgent dental issues. Contact us as soon as possible in the event of an emergency.",
              pricing: "Consultation: $80, Emergency Visit: $150",
              img: emergencyImage
            },
            {
              title: "Orthodontics",
              description: "Achieve a straighter, healthier smile with our orthodontic services, including traditional braces and clear aligners. Our personalized treatment plans ensure optimal results and lifelong benefits.",
              pricing: "Consultation: $100, Braces: Starting at $3,000, Aligners: Starting at $2,500",
              img: orthoImage
            },
            {
              title: "Restorative Dentistry",
              description: "Restore your smile with our restorative dentistry options, including fillings, crowns, bridges, and implants. Each treatment is designed to be durable, functional, and natural-looking.",
              pricing: "Fillings: $150/tooth, Crowns: $900/tooth, Implants: Starting at $2,000",
              img: restorativeImage
            },
            {
              title: "Pediatric Dentistry",
              description: "Our pediatric dental care services create a friendly and comfortable environment for children. We focus on preventive care, helping young patients build healthy habits from an early age.",
              pricing: "Pediatric Exam & Cleaning: $80, Sealants: $40/tooth",
              img: pediatricImage
            }
          ].map((service, index) => (
            <motion.div
              key={index}
              className="bg-white rounded-lg shadow-lg p-6"
              variants={serviceVariants}
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true, amount: 0.3 }}
              transition={{ duration: 0.6, delay: index * 0.2 }}
            >
              <img src={service.img} alt={service.title} className="rounded-lg w-full h-64 object-cover mb-4" />
              <h2 className="text-2xl font-bold text-blue-800 mb-2">{service.title}</h2>
              <p className="text-gray-700 mb-4">{service.description}</p>
              <p className="text-lg font-semibold text-blue-800">{service.pricing}</p>
            </motion.div>
          ))}
        </div>
      </section>

      {/* Call to Action */}
      <section className="py-16 px-8 md:px-16 bg-blue-800 text-white text-center">
        <h2 className="text-3xl font-bold mb-4">Book Your Appointment Today</h2>
        <p className="text-lg mb-6">Contact us to schedule an appointment or to learn more about our dental services.</p>
        <a href="/healthcare/contact" className="bg-yellow-400 text-gray-900 px-6 py-3 rounded-lg font-medium hover:bg-yellow-500 transition">
          Contact Us
        </a>
      </section>
    </div>
  );
};

export default HealthcareServices;
