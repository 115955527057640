import React from 'react';
import RealEstateNavBar from './RealEstateNavBar';
import property1 from '../../assets/realestate/hows6.jpg'
import property2 from '../../assets/realestate/re1.webp'
import property3 from '../../assets/realestate/re2.webp'
import property4 from '../../assets/realestate/re3.webp'


const RealEstateAbout = () => {
  return (
    <div className="bg-[#FDF0D5] text-gray-900 font-montserrat"> {/* Use Montserrat for body text */}
      <RealEstateNavBar />
      <section className="py-16 px-8 md:px-16">
        <h2 className="text-4xl font-playfair mb-6 text-center text-[#A12B2F]">About NestQuest</h2> {/* Use Playfair Display for headings */}
        
        <p className="text-gray-700 text-lg text-center max-w-3xl mx-auto mb-8">
          At NestQuest, we pride ourselves on being your trusted partner in navigating the real estate market. With years of experience, our dedicated team is committed to providing personalized service that caters to your unique needs, whether you're a first-time buyer or a seasoned investor.
        </p>
        
        <div className="flex flex-col md:flex-row justify-center gap-8 mb-12">
          <div className="flex-1">
            <img src={property2} alt="Our Team" className="w-full h-64 object-cover rounded-lg shadow-lg" />
            <h3 className="text-xl font-semibold mt-4 text-center">Meet Our Team</h3>
          </div>
          <div className="flex-1">
            <img src={property1} alt="Featured Properties" className="w-full h-64 object-cover rounded-lg shadow-lg" />
            <h3 className="text-xl font-semibold mt-4 text-center">Featured Properties</h3>
          </div>
        </div>

        <p className="text-gray-700 text-lg text-center max-w-3xl mx-auto mb-4">
          Our extensive portfolio features a diverse range of properties, from luxurious villas to cozy apartments. We believe in creating lasting relationships with our clients, ensuring that you receive expert guidance and support every step of the way.
        </p>

        <p className="text-gray-700 text-lg text-center max-w-3xl mx-auto mb-4">
          At NestQuest, we understand that buying or selling a home can be one of life's most significant decisions. That's why we focus on delivering exceptional service, leveraging our market knowledge to help you make informed choices that align with your lifestyle and financial goals.
        </p>

        <div className="flex flex-col md:flex-row justify-center gap-8 mb-12">
          <div className="flex-1">
            <img src={property3} alt="Support Services" className="w-full h-64 object-cover rounded-lg shadow-lg" />
            <h3 className="text-xl font-semibold mt-4 text-center">Ongoing Support</h3>
          </div>
          <div className="flex-1">
            <img src={property4} alt="Community" className="w-full h-64 object-cover rounded-lg shadow-lg" />
            <h3 className="text-xl font-semibold mt-4 text-center">Our Community</h3>
          </div>
        </div>

        <p className="text-gray-700 text-lg text-center max-w-3xl mx-auto mb-4">
          With our passion for real estate and unwavering dedication to client satisfaction, we are here to help you find your dream home or achieve the best price for your property. Let us turn your real estate aspirations into reality!
        </p>
        
        <div className="mt-10 text-center">
          <a href="/realestate/contact" className="bg-[#A12B2F] text-[#FDF0D5] px-8 py-3 rounded-lg font-medium hover:bg-[#C44536] transition"> {/* Brick Red hover */}
            Learn More
          </a>
        </div>
      </section>
    </div>
  );
};

export default RealEstateAbout;
