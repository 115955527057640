// src/pages/HomePage.js

import React from "react";
import Card from "../components/Card";

// Import images
import eduImage from "../assets/education/edu1.webp";
import healthcareImage from "../assets/healthcare/dentist.jpg";
import logisticsImage from "../assets/logistics/truck.jpg";
import woodworkImage from "../assets/furniture/wood1.jpg";
import realEstateImage from "../assets/realestate/hows6.jpg";
import craftBeerImage from "../assets/food/bbc2.webp";
import financeImage from "../assets/finance/finance.jpg";
import legalImage from "../assets/legal/law2.jpg";
import ecommerceImage from "../assets/ecommerce/logo3.webp";

const HomePage = () => {
  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-900 to-gray-700 flex flex-col items-center py-12 font-play">
     
      {/* Title Section */}
      <h1 className="text-5xl font-extrabold text-center text-purple-400 mb-16">
        Welcome to gigaDesign
      </h1>
      <p className="text-xl text-gray-300 text-center mb-12 max-w-2xl">
        Explore our portfolio of professionally designed business templates, 
        each crafted to bring your vision to life with a modern edge.
      </p>

      {/* Card Grid */}
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8 p-4 w-full max-w-5xl">
        <Card
          title="Healthcare - Smiles Dental"
          link="/healthcare"
          image={healthcareImage}
          description="A sleek, patient-centric design for modern dental practices, fostering trust and comfort."
        />
        <Card
          title="Logistics - Truckforce"
          link="/logistics"
          image={logisticsImage}
          description="Optimized for logistics services, this template highlights efficient tracking and reliable operations."
        />
        <Card
          title="Woodwork - TimberWorks"
          link="/furniture"
          image={woodworkImage}
          description="Showcase your craftsmanship with a sophisticated layout for artisan woodworking studios."
        />
        <Card
          title="Real Estate - NestQuest"
          link="/realestate"
          image={realEstateImage}
          description="Designed for property pros, this template blends professionalism with welcoming aesthetics."
        />
        <Card
          title="Craft Beer - Bubbly Brew Co."
          link="/food"
          image={craftBeerImage}
          description="A vibrant, funky layout to capture the essence of your craft beer brand."
        />
        <Card
          title="Finance - PaySphere"
          link="/finance"
          image={financeImage}
          description="A secure and professional design tailored for finance and payment solutions."
        />
        <Card
          title="Education - BitcoinIQ"
          link="/education"
          image={eduImage}
          description="A modern, accessible template for online learning and educational resources."
        />
        <Card
          title="Legal - Lexis & Anchor LLP"
          link="/legal"
          image={legalImage}
          description="A refined layout for law firms, focusing on clarity and professionalism."
        />
        <Card
          title="E-commerce - Graf Streetwear"
          link="/ecommerce"
          image={ecommerceImage}
          description="Urban and edgy, this e-commerce template is perfect for retail brands with attitude."
        />
      </div>
    </div>
  );
};

export default HomePage;
