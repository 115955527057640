// src/App.js

import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Footer from "./components/Footer";
import HomePage from "./pages/HomePage";
import FurnitureHome from "./pages/Furniture/FurnitureHome";
import FurnitureServices from "./pages/Furniture/FurnitureServices";
import FurnitureAbout from "./pages/Furniture/FurnitureAbout";
import FurnitureContact from "./pages/Furniture/FurnitureContact";
import ECommerceAbout from "./pages/ECommerce/ECommerceAbout";
import ECommerceContact from "./pages/ECommerce/ECommerceContact";
import ECommerceHome from "./pages/ECommerce/ECommerceHome";
import ECommerceShop from "./pages/ECommerce/ECommerceShop";
import EducationArticles from "./pages/Education/EducationArticles";
import EducationTopics from "./pages/Education/EducationTopics";
import EducationHome from "./pages/Education/EducationHome";
import EducationSample from "./pages/Education/EducationSample";
import FinanceFeatures from "./pages/Finance/FinanceFeatures";
import FinanceHome from "./pages/Finance/FinanceHome";
import FinancePricing from "./pages/Finance/FinancePricing";
import FinanceContact from "./pages/Finance/FinanceContact";
import FoodAbout from "./pages/Food/FoodAbout";
import FoodContact from "./pages/Food/FoodContact";
import FoodHome from "./pages/Food/FoodHome";
import FoodEvents from "./pages/Food/FoodEvents";
import HealthcareAbout from "./pages/Healthcare/HealthcareAbout";
import HealthcareContact from "./pages/Healthcare/HealthcareContact";
import HealthcareHome from "./pages/Healthcare/HealthcareHome";
import HealthcareServices from "./pages/Healthcare/HealthcareServices";
import LegalAbout from "./pages/Legal/LegalAbout";
import LegalContact from "./pages/Legal/LegalContact";
import LegalHome from "./pages/Legal/LegalHome";
import LegalServices from "./pages/Legal/LegalServices";
import LogisticsAbout from "./pages/Logistics/LogisticsAbout";
import LogisticsContact from "./pages/Logistics/LogisticsContact";
import LogisticsHome from "./pages/Logistics/LogisticsHome";
import LogisticsServices from "./pages/Logistics/LogisticsServices";
import RealEstateAbout from "./pages/RealEstate/RealEstateAbout";
import RealEstateContact from "./pages/RealEstate/RealEstateContact";
import RealEstateHome from "./pages/RealEstate/RealEstateHome";
import RealEstateFeatured from "./pages/RealEstate/RealEstateFeatured";
import RealEstateTestimonials from "./pages/RealEstate/RealEstateTestimonials";
import ScrollToTop from "./components/ScrollToTop";
import HomeIcon from "./components/HomeIcon";

// Import additional templates here as needed

function App() {
  return (
    <Router>
      <ScrollToTop/>
      <HomeIcon/>
      <Routes>
        <Route path="/" element={<HomePage />} />
        {/* Ecommerce*/}
        <Route path="/ecommerce" element={<ECommerceHome />} />
        <Route path="/ecommerce/shop" element={<ECommerceShop />} />
        <Route path="/ecommerce/about" element={<ECommerceAbout />} />
        <Route path="/ecommerce/contact" element={<ECommerceContact />} />

        {/* Education*/}
        <Route path="/education" element={<EducationHome />} />
        <Route path="/education/articles" element={<EducationArticles />} />
        <Route path="/education/topics" element={<EducationTopics />} />
        <Route path="/education/sample" element={<EducationSample />} />

      
        {/* Finance*/}
        <Route path="/finance" element={<FinanceHome />} />
        <Route path="/finance/features" element={<FinanceFeatures />} />
        <Route path="/finance/pricing" element={<FinancePricing />} />
        <Route path="/finance/contact" element={<FinanceContact />} />
        {/* Food*/}
        <Route path="/food" element={<FoodHome />} />
        <Route path="/food/events" element={<FoodEvents />} />
        <Route path="/food/about" element={<FoodAbout />} />
        <Route path="/food/contact" element={<FoodContact />} />
        {/* Furniture*/}
        <Route path="/furniture" element={<FurnitureHome />} />
        <Route path="/furniture/services" element={<FurnitureServices />} />
        <Route path="/furniture/about" element={<FurnitureAbout />} />
        <Route path="/furniture/contact" element={<FurnitureContact />} />
        {/* Healthcare*/}
        <Route path="/healthcare" element={<HealthcareHome />} />
        <Route path="/healthcare/services" element={<HealthcareServices />} />
        <Route path="/healthcare/about" element={<HealthcareAbout />} />
        <Route path="/healthcare/contact" element={<HealthcareContact />} />
        {/* Legal*/}
        <Route path="/legal" element={<LegalHome />} />
        <Route path="/legal/services" element={<LegalServices />} />
        <Route path="/legal/about" element={<LegalAbout />} />
        <Route path="/legal/contact" element={<LegalContact />} />
        {/* Logistics*/}
        <Route path="/logistics" element={<LogisticsHome />} />
        <Route path="/logistics/services" element={<LogisticsServices />} />
        <Route path="/logistics/about" element={<LogisticsAbout />} />
        <Route path="/logistics/contact" element={<LogisticsContact />} />
        {/* RealEstate*/}
        <Route path="/realestate" element={<RealEstateHome />} />
        <Route path="/realestate/featured" element={<RealEstateFeatured />} />
        <Route path="/realestate/about" element={<RealEstateAbout />} />
        <Route path="/realestate/contact" element={<RealEstateContact />} />
        <Route path="/realestate/testimonials" element={<RealEstateTestimonials />} />

        {/* Add routes for other business templates */}
      </Routes>
      <Footer/>
    </Router>
  );
}

export default App;
