import React from 'react';
import FurnitureNavBar from './FurnitureNavBar';

const FurnitureServices = () => {
  return (
    <div className="bg-gray-100 text-gray-800 font-sans" style={{ fontFamily: 'Cormorant Garamond, serif' }}>
        <FurnitureNavBar/>
      {/* Hero Section */}
      <section className="bg-gray-800 text-gray-100 p-12 text-center">
        <h1 className="text-4xl font-bold text-yellow-400 mb-4">Our Services</h1>
        <p className="text-lg max-w-3xl mx-auto">
          At TimberWorks, we specialize in crafting unique, custom woodwork designed to enrich your living space with warmth and character. Each piece is handcrafted to perfection, reflecting both our passion for quality and your unique style.
        </p>
      </section>

      {/* Services Overview */}
      <section className="py-16 px-8 md:px-16 bg-gray-100">
        <h2 className="text-3xl font-bold text-center mb-6 text-gray-800">Our Craftsmanship Offerings</h2>
        <p className="text-lg text-gray-800 text-center max-w-3xl mx-auto mb-12">
          TimberWorks offers a range of bespoke services to meet every need. From elegant dining tables and bookshelves to detailed cabinetry and custom furniture pieces, we bring artistry and precision to each project.
        </p>
        
        <div className="grid md:grid-cols-3 gap-12">
          {/* Service Item 1 */}
          <div className="bg-white rounded-lg shadow-lg p-6 text-center">
            <h3 className="text-2xl font-semibold text-yellow-600">Custom Furniture</h3>
            <p className="text-gray-700 mt-4">
              Our custom furniture service is ideal for clients looking for pieces that truly embody their taste. Each piece is made from scratch, using sustainably sourced wood, and crafted to last for generations.
            </p>
          </div>

          {/* Service Item 2 */}
          <div className="bg-white rounded-lg shadow-lg p-6 text-center">
            <h3 className="text-2xl font-semibold text-yellow-600">Restoration and Refinishing</h3>
            <p className="text-gray-700 mt-4">
              Preserve and revitalize your cherished pieces with our expert restoration service. From restoring vintage furniture to updating worn surfaces, we breathe new life into each piece.
            </p>
          </div>

          {/* Service Item 3 */}
          <div className="bg-white rounded-lg shadow-lg p-6 text-center">
            <h3 className="text-2xl font-semibold text-yellow-600">Kitchen and Cabinetry</h3>
            <p className="text-gray-700 mt-4">
              Our custom cabinetry and kitchen designs are tailored to fit your space. We combine form and function to create kitchens that are both stylish and highly practical.
            </p>
          </div>
        </div>
      </section>

      {/* Pricing Section */}
      <section className="py-16 px-8 md:px-16 bg-gray-200">
        <h2 className="text-3xl font-bold text-center text-gray-800 mb-6">Pricing and Packages</h2>
        <p className="text-lg text-center max-w-3xl mx-auto text-gray-800 mb-12">
          Our prices reflect the craftsmanship and quality that goes into each piece. We offer transparent pricing packages to make it easy to bring your vision to life.
        </p>
        
        <div className="grid md:grid-cols-3 gap-12">
          {/* Package 1 */}
          <div className="bg-white rounded-lg shadow-lg p-8 text-center">
            <h3 className="text-2xl font-semibold text-yellow-600">Basic Package</h3>
            <p className="text-gray-700 mt-4">Perfect for small projects and minor restorations.</p>
            <ul className="mt-4 text-gray-700 text-lg">
              <li>Simple tables and chairs</li>
              <li>Minor wood repairs</li>
              <li>Finishing and touch-ups</li>
            </ul>
            <p className="text-3xl font-bold text-gray-800 mt-6">$500 - $1,000</p>
          </div>

          {/* Package 2 */}
          <div className="bg-white rounded-lg shadow-lg p-8 text-center">
            <h3 className="text-2xl font-semibold text-yellow-600">Premium Package</h3>
            <p className="text-gray-700 mt-4">Ideal for custom pieces and larger projects.</p>
            <ul className="mt-4 text-gray-700 text-lg">
              <li>Custom bookshelves and tables</li>
              <li>Detailed woodworking</li>
              <li>Mid-size restorations</li>
            </ul>
            <p className="text-3xl font-bold text-gray-800 mt-6">$1,000 - $3,000</p>
          </div>

          {/* Package 3 */}
          <div className="bg-white rounded-lg shadow-lg p-8 text-center">
            <h3 className="text-2xl font-semibold text-yellow-600">Luxury Package</h3>
            <p className="text-gray-700 mt-4">For grand projects and full customization.</p>
            <ul className="mt-4 text-gray-700 text-lg">
              <li>Custom cabinetry and kitchens</li>
              <li>Complete restorations</li>
              <li>Luxury furniture designs</li>
            </ul>
            <p className="text-3xl font-bold text-gray-800 mt-6">$3,000+</p>
          </div>
        </div>
      </section>

      {/* Contact Call-to-Action */}
      <section className="py-16 px-8 md:px-16 bg-gray-800 text-white text-center">
        <h2 className="text-3xl font-bold mb-4" style={{ fontFamily: 'Cormorant Garamond, serif' }}>
          Ready to Start Your Project?
        </h2>
        <p className="text-lg mb-6" style={{ fontFamily: 'Cormorant Garamond, serif', fontWeight: 300 }}>
          We’d love to hear about your vision and discuss how we can bring it to life. Reach out today for a consultation and let’s create something extraordinary together.
        </p>
        <a href="/furniture/contact" className="bg-white text-gray-800 px-6 py-3 rounded-lg font-medium hover:bg-gray-100 transition" style={{ fontFamily: 'Cormorant Garamond, serif' }}>
          Contact Us
        </a>
      </section>
    </div>
  );
};

export default FurnitureServices;
