// src/pages/Education/EducationNavBar.js

import React from "react";
import { Link } from "react-router-dom";

const EducationNavBar = () => (
  <nav className="bg-slate-900 text-amber-500 p-6 flex justify-center space-x-8 font-play shadow-lg">
    <Link to="/education" className="hover:text-white text-lg transition duration-200 ease-in-out">Home</Link>
    <Link to="/education/articles" className="hover:text-white text-lg transition duration-200 ease-in-out">Articles</Link>
    <Link to="/education/topics" className="hover:text-white text-lg transition duration-200 ease-in-out">Topics</Link>
  </nav>
);

export default EducationNavBar;
