import React from "react";
import FinanceNavBar from "./FinanceNavBar"; // Navbar component

const FinanceContact = () => {
  return (
    <div className="min-h-screen bg-black text-white font-play">
      <FinanceNavBar />

      {/* Page Heading */}
      <div className="text-center my-8">
        <h1 className="text-6xl font-extrabold text-[#FFD700] mb-4">Contact Us</h1>
        <p className="text-lg mb-8">We'd love to hear from you! Reach out with any questions or feedback.</p>
      </div>

      {/* Contact Information */}
      <div className="max-w-screen-lg mx-auto p-6 grid grid-cols-1 gap-8">
        {/* Contact Info Section */}
        <section className="relative border-4 border-purple-600 rounded-lg bg-gray-800 p-6">
          <h2 className="text-4xl font-semibold mb-4">Get in Touch</h2>
          <p className="text-lg mb-4">For any inquiries, please contact us at:</p>
          <p className="text-lg mb-2"><strong>Email:</strong> support@paysphere.com</p>
          <p className="text-lg mb-2"><strong>Phone:</strong> +1 (234) 567-8901</p>
          <p className="text-lg mb-2"><strong>Address:</strong> 123 PaySphere St, Financia, CA 90210</p>
        </section>

        {/* Contact Form Section */}
        <section className="relative border-4 border-purple-600 rounded-lg bg-gray-800 p-6">
          <h2 className="text-4xl font-semibold mb-4">Contact Form</h2>
          <form>
            <div className="mb-4">
              <label className="block text-lg mb-2" htmlFor="name">Name</label>
              <input 
                type="text" 
                id="name" 
                className="w-full p-3 rounded-lg bg-gray-700 text-white" 
                placeholder="Your Name" 
                required 
              />
            </div>
            <div className="mb-4">
              <label className="block text-lg mb-2" htmlFor="email">Email</label>
              <input 
                type="email" 
                id="email" 
                className="w-full p-3 rounded-lg bg-gray-700 text-white" 
                placeholder="Your Email" 
                required 
              />
            </div>
            <div className="mb-4">
              <label className="block text-lg mb-2" htmlFor="message">Message</label>
              <textarea 
                id="message" 
                rows="5" 
                className="w-full p-3 rounded-lg bg-gray-700 text-white" 
                placeholder="Your Message" 
                required 
              />
            </div>
            <button 
              type="submit" 
              className="bg-purple-600 text-white px-4 py-2 rounded-lg font-bold hover:bg-purple-400 transition"
            >
              Send Message
            </button>
          </form>
        </section>
      </div>
    </div>
  );
};

export default FinanceContact;
