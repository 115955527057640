import React from "react";
import { Link } from "react-router-dom";

const LegalNavBar = () => (
  <nav className="bg-slate-900 text-gray-200 p-4 flex justify-center space-x-8">
    <Link 
      to="/legal" 
      className="hover:text-teal-400 font-serif text-lg transition duration-300"
    >
      Home
    </Link>
    <Link 
      to="/legal/services" 
      className="hover:text-teal-400 font-serif text-lg transition duration-300"
    >
      Services
    </Link>
    <Link 
      to="/legal/about" 
      className="hover:text-teal-400 font-serif text-lg transition duration-300"
    >
      About
    </Link>
    <Link 
      to="/legal/contact" 
      className="hover:text-teal-400 font-serif text-lg transition duration-300"
    >
      Contact
    </Link>
  </nav>
);

export default LegalNavBar;
