import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FiHome } from 'react-icons/fi'; // Import a home icon from react-icons

const HomeIcon = () => {
  const navigate = useNavigate();

  return (
    <button
      onClick={() => navigate('/')}
      className="fixed top-20 left-4 z-[9999] bg-gray-700 text-white p-2 rounded-full hover:bg-gray-600 transition"
      aria-label="Go to Home"
    >
      <FiHome size={24} />
    </button>
  );
};

export default HomeIcon;
