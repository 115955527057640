import React from 'react';
import EducationNavBar from './EducationNavBar';
import heroImage from '../../assets/education/edu1.webp';
import articleImage1 from '../../assets/education/btc3.jpg';
import articleImage2 from '../../assets/education/btc6.webp';
import articleImage3 from '../../assets/education/btc2.webp';
import topicImage1 from '../../assets/education/btc4.webp';
import topicImage2 from '../../assets/education/btc5.webp';
import topicImage3 from '../../assets/education/btc1.webp';
import socialIcon1 from '../../assets/education/fb.jpg';
import socialIcon2 from '../../assets/education/x.jpg';
import socialIcon3 from '../../assets/education/ig.jpg';
import contributorImage from '../../assets/legal/law6.jpg';

const EducationHome = () => {
  return (
    <div className="bg-gray-800 text-slate-200 font-play">
      <EducationNavBar />

      {/* Hero Section */}
      <section
        className="relative bg-cover bg-center h-screen grid grid-rows-2"
        style={{ backgroundImage: `url(${heroImage})` }}
      >
        <div className="absolute inset-0 bg-slate-900 opacity-50"></div>
        
        {/* Site Title at Top */}
        <div className="relative z-10 flex items-top justify-center py-20">
          <h1 className="text-7xl font-merriweather text-white">BitcoinIQ</h1>
        </div>
        
        {/* Hero Text at Bottom */}
        <div className="relative z-10 flex flex-col items-center justify-end pb-12 text-center text-white px-4">
          <p className="text-2xl mb-8 font-source-sans">Demystifying Digital Gold, One Insight at a Time.</p>
          <a href="/education/articles" className="bg-amber-500 text-slate-900 px-8 py-4 rounded-lg font-lato font-medium hover:bg-amber-600 transition">
            Start Your Journey
          </a>
        </div>
      </section>

      {/* Fresh Insights Grid */}
      <section className="py-16 px-8 md:px-16 bg-gray-800">
        <h2 className="text-4xl font-merriweather mb-8 text-center text-white">Fresh Insights</h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {[
            { title: "Bitcoin Basics", image: articleImage1 },
            { title: "Blockchain 101", image: articleImage2 },
            { title: "Investing Wisely", image: articleImage3 },
          ].map((article, index) => (
            <div key={index} className="bg-gray-800 border border-gray-700 rounded-lg shadow-lg p-6">
              <img src={article.image} alt={article.title} className="rounded mb-4" />
              <h3 className="text-2xl font-semibold mb-2 font-merriweather text-white">{article.title}</h3>
              <p className="text-gray-400 font-source-sans">
                Discover the essential principles of {article.title.toLowerCase()}, from the foundation of blockchain to the smart strategies in the world of digital investments. Unlock key insights to stay ahead in the crypto landscape.
              </p>
              <div className="mt-6">
              <a href="/education/sample" className=" bg-amber-500 text-slate-900 px-4 py-2 rounded font-lato font-medium hover:bg-amber-600 transition">
                Dive In
              </a>
              </div>
            </div>
          ))}
        </div>
      </section>

      {/* Topics of Interest */}
      <section className="py-16 px-8 md:px-16 bg-slate-900">
        <h2 className="text-4xl font-merriweather mb-8 text-center text-white">Explore by Topic</h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {[
            { title: "Bitcoin History", image: topicImage1 },
            { title: "Market Trends", image: topicImage2 },
            { title: "Security Tips", image: topicImage3 },
          ].map((topic, index) => (
            <div key={index} className="bg-gray-800 border border-gray-700 rounded-lg shadow-lg p-6 text-center">
              <img src={topic.image} alt={topic.title} className="rounded mb-4" />
              <h3 className="text-xl font-semibold font-merriweather text-white">{topic.title}</h3>
            </div>
          ))}
        </div>
      </section>

      {/* Expert Contributors Section */}
      <section className="py-16 px-8 md:px-16 bg-gray-800">
        <h2 className="text-4xl font-merriweather mb-8 text-center text-white">Our Expert Contributors</h2>
        <div className="grid grid-cols-1 md:grid-cols-4 gap-8 justify-center">
          {[1, 2, 3, 4].map((_, index) => (
            <div key={index} className="text-center">
              <img
                src={contributorImage}
                alt={`Contributor ${index + 1}`}
                className="rounded-full w-24 h-24 mx-auto mb-2"
              />
              <p className="font-merriweather font-semibold text-slate-200">Contributor {index + 1}</p>
              <p className="text-gray-400 font-source-sans">Expert in Blockchain & Finance</p>
            </div>
          ))}
        </div>
      </section>

      {/* Footer */}
      <footer className="py-8 bg-slate-900 text-white">
        <div className="container mx-auto grid grid-cols-1 md:grid-cols-3 gap-4 text-center md:text-left">
          <div>
            <h3 className="text-lg font-semibold font-merriweather">Our Mission</h3>
            <p className="text-sm font-source-sans text-gray-400">Guiding curious minds to the forefront of the financial future.</p>
          </div>
          <div>
            <h3 className="text-lg font-semibold font-merriweather">Navigate</h3>
            <ul className="space-y-1 font-source-sans text-gray-400">
              <li>Learning Hub</li>
              <li>Topics</li>
              <li>Contact</li>
            </ul>
          </div>
          <div>
            <h3 className="text-lg font-semibold font-merriweather">Stay Connected</h3>
            <div className="flex justify-center md:justify-start space-x-4">
              <img src={socialIcon1} alt="Facebook" className="hover:text-emerald-500 w-6 h-6" />
              <img src={socialIcon2} alt="Twitter" className="hover:text-emerald-500 w-6 h-6" />
              <img src={socialIcon3} alt="Instagram" className="hover:text-emerald-500 w-6 h-6" />
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default EducationHome;
