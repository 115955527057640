import React from 'react';
import ECommerceNavBar from './ECommerceNavBar';
import parallaxImage2 from '../../assets/ecommerce/graf2.webp';
import parallaxImage3 from '../../assets/ecommerce/graf3.webp';
import parallaxImage4 from '../../assets/ecommerce/graf9.webp';


const products = [
  { id: 1, name: "Street Hoodie", price: "$89.99", image: parallaxImage3 },
  { id: 2, name: "Urban Tee", price: "$49.99", image: parallaxImage2 },
  { id: 3, name: "Graffiti Jacket", price: "$129.99", image: parallaxImage4 },
  // Add more products as needed
];

const ECommerceShop = () => {
  return (
    <div className="bg-slate-900 text-white min-h-screen">
      <ECommerceNavBar />
      
      {/* Header */}
      <header className="text-center mb-12">
        <h1 className="text-5xl font-extrabold text-yellow-500 py-10 font-anton">Shop Graf Streetwear</h1>
        <p className="text-gray-300 mt-4 font-oswald">Exclusive, limited-edition styles crafted for urban life.</p>
      </header>

      {/* Product Grid */}
      <section className="container mx-auto px-6 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-8">
        {products.map(product => (
          <div key={product.id} className="bg-gray-800 rounded-lg shadow-lg overflow-hidden transition transform hover:scale-105">
            <img src={product.image} alt={product.name} className="w-full h-64 object-cover" />
            <div className="p-6">
              <h2 className="text-xl font-semibold text-pink-600 font-oswald">{product.name}</h2>
              <p className="text-yellow-500 mt-2 font-oswald">{product.price}</p>
              <button className="mt-4 bg-blue-700 text-white px-4 py-2 rounded-full font-medium hover:bg-blue-800 transition font-anton">
                View Product
              </button>
            </div>
          </div>
        ))}
      </section>

      {/* Footer CTA */}
      <div className="mt-20 text-center">
        <p className="text-lg text-gray-400 font-oswald mb-6">Sign up for exclusive releases and discounts!</p>
        <a href="/ecommerce/contact" className="bg-yellow-500 text-gray-900 px-8 py-3 rounded-full font-semibold mt-4 hover:bg-yellow-600 transition font-anton">
          Subscribe Now
        </a>
      </div>
    </div>
  );
};

export default ECommerceShop;
