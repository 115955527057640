import React from "react";
import LegalNavBar from "./LegalNavBar";
import lawImage1 from '../../assets/legal/law1.jpg';
import lawImage from '../../assets/legal/law2.jpg';
import lawImage2 from '../../assets/legal/law6.jpg';
import lawImage3 from '../../assets/legal/law3.jpg';





const LegalAbout = () => {
  return (
    <div className="min-h-screen bg-slate-800 text-slate-200 font-sans">
      <LegalNavBar />

      {/* Header Section */}
      <header className="bg-slate-900 text-white py-12 text-center">
        <h1 className="text-5xl font-serif font-bold text-teal-400">
          About Us
        </h1>
        <p className="text-lg mt-4 max-w-3xl mx-auto text-slate-300">
          Providing reliable legal expertise with dedication, integrity, and a
          client-centered approach.
        </p>
      </header>

      {/* Main Content */}
      <main className="py-16 px-8 max-w-screen-lg mx-auto space-y-16">
        {/* Our Story Section */}
        <section className="space-y-8">
          <h2 className="text-3xl font-serif font-bold text-teal-400 mb-4">
            Our Story
          </h2>
          <div className="flex flex-col md:flex-row items-start md:space-x-8">
            <div className="w-full md:w-1/2 space-y-4">
              <p className="text-slate-300">
                Founded on the principles of integrity and excellence, our firm
                has grown from a small office to a well-respected legal practice
                known for unwavering commitment to client success. Our team of
                skilled attorneys brings decades of combined experience,
                ensuring that each client receives top-tier legal support
                tailored to their needs.
              </p>
              <p className="text-slate-300">
                From humble beginnings, we’ve expanded our expertise across a
                broad spectrum of legal fields, establishing a reputation for
                handling complex cases with precision, insight, and empathy.
              </p>
            </div>
            <div className="w-full md:w-1/2 bg-slate-700 rounded-lg p-4">
              <div className="h-64 bg-cover bg-center rounded-md" style={{backgroundImage: `url(${lawImage1})`,}}>
                {/* Placeholder for Story Image */}
              </div>
            </div>
          </div>
        </section>

        {/* Our Values Section */}
        <section className="space-y-8">
          <h2 className="text-3xl font-serif font-bold text-teal-400 mb-4">
            Our Values
          </h2>
          <div className="flex flex-col md:flex-row items-start md:space-x-8">
            <div className="w-full md:w-1/2 space-y-4">
              <p className="text-slate-300">
                At the core of our practice are values that define who we are
                and guide everything we do. We believe in transparency,
                compassion, and a relentless pursuit of justice for our clients.
              </p>
              <ul className="list-disc list-inside text-slate-400 space-y-2">
                <li><strong>Integrity:</strong> Upholding the highest standards of ethics and professionalism.</li>
                <li><strong>Client-Centered Approach:</strong> Prioritizing the unique needs and goals of each client.</li>
                <li><strong>Excellence:</strong> Continuously striving for legal mastery in every case we take.</li>
                <li><strong>Respect:</strong> Treating every client and case with dignity and empathy.</li>
              </ul>
            </div>
            <div className="w-full md:w-1/2 bg-slate-700 rounded-lg p-4">
              <div className="h-64 bg-cover bg-center rounded-md" style={{backgroundImage: `url(${lawImage})`,}}>
                {/* Placeholder for Values Image */}
              </div>
            </div>
          </div>
        </section>

        {/* Our Team Section */}
        <section className="space-y-8">
          <h2 className="text-3xl font-serif font-bold text-teal-400 mb-4">
            Meet Our Team
          </h2>
          <p className="text-slate-300 mb-8">
            Our team is comprised of experienced attorneys dedicated to
            providing insightful and effective representation. Each team member
            brings a unique perspective and a commitment to client success.
          </p>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {/* Team Member Cards */}
            {["John Doe", "Jane Smith", "Alice Brown"].map((name, index) => (
              <div key={index} className="bg-slate-700 rounded-lg p-6 shadow-lg">
                <div className="h-40 bg-cover bg-center rounded-md mb-4" style={{backgroundImage: `url(${lawImage2})`,}}>
                  {/* Placeholder for Team Member Image */}
                </div>
                <h3 className="text-2xl font-serif font-bold text-teal-400">
                  {name}
                </h3>
                <p className="text-slate-400">
                  Senior Attorney specializing in complex litigation and
                  client-centered representation.
                </p>
              </div>
            ))}
          </div>
        </section>

        {/* Our Approach Section */}
        <section className="space-y-8">
          <h2 className="text-3xl font-serif font-bold text-teal-400 mb-4">
            Our Approach
          </h2>
          <div className="flex flex-col md:flex-row items-start md:space-x-8">
            <div className="w-full md:w-1/2 space-y-4">
              <p className="text-slate-300">
                We approach each case with a strategy uniquely tailored to the
                client’s needs, beginning with a thorough consultation to
                understand all facets of their situation. From there, our team
                develops a plan focused on achieving the best possible outcome
                with a clear, client-centered approach.
              </p>
              <p className="text-slate-300">
                Our commitment to transparent communication and rigorous
                advocacy ensures that every client feels informed, supported,
                and empowered throughout their legal journey.
              </p>
            </div>
            <div className="w-full md:w-1/2 bg-slate-700 rounded-lg p-4">
              <div className="h-64 bg-cover bg-center rounded-md" style={{backgroundImage: `url(${lawImage3})`,}}>
                {/* Placeholder for Approach Image */}
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
};

export default LegalAbout;
