import React from "react";
import LogisticsNavBar from "./LogisticsNavBar";

const LogisticsServices = () => {
  return (
    <div className="bg-gray-900 text-gray-200 font-ubuntu">
      <LogisticsNavBar />
      {/* Header */}
      <header className="bg-gray-800 text-center py-10">
        <h1 className="text-5xl font-bold text-orange-600" style={{ fontFamily: 'Lobster, sans-serif' }}>Our Services</h1>
        <p className="mt-4 text-gray-200 max-w-2xl mx-auto">
          At TruckForce, we provide comprehensive logistics solutions to meet the needs of businesses across industries. Our commitment to excellence and reliability has established us as a trusted logistics partner, capable of delivering tailored solutions for any supply chain challenge.
        </p>
      </header>

      {/* Services Section */}
      <section className="py-16 px-8 md:px-16 bg-gray-900">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-12">
          {/* Refrigerated Transport */}
          <div className="bg-gray-800 p-8 rounded-lg shadow-lg">
            <h2 className="text-3xl font-bold text-orange-600 mb-4">Refrigerated Transport</h2>
            <p className="text-gray-200 mb-4">
              For industries where temperature control is critical, TruckForce offers state-of-the-art refrigerated transport solutions. Our fleet is equipped with advanced cooling technology to maintain optimal conditions for perishable goods, including food products, pharmaceuticals, and sensitive materials. 
            </p>
            <p className="text-gray-200">
              Every vehicle is monitored to ensure a consistent temperature throughout transit. With real-time tracking, you can monitor the journey of your goods, and our team of logistics experts is on standby to address any concerns along the way. Our refrigerated transport service is a cornerstone of our commitment to safe, efficient, and reliable deliveries.
            </p>
          </div>

          {/* 24/7 Service */}
          <div className="bg-gray-800 p-8 rounded-lg shadow-lg">
            <h2 className="text-3xl font-bold text-orange-600 mb-4">24/7 Availability</h2>
            <p className="text-gray-200 mb-4">
              Time-sensitive shipments require a partner that operates without restrictions. TruckForce offers 24/7 services, ensuring your logistics needs are met around the clock. We understand the demands of a fast-paced business environment, where each minute can impact your bottom line.
            </p>
            <p className="text-gray-200">
              Our dedicated support team and robust infrastructure allow us to respond quickly and efficiently at any time, day or night. Whether you need assistance with an urgent delivery or require immediate updates on a shipment, TruckForce is always just a call away. Our commitment to continuous service means your supply chain never has to pause.
            </p>
          </div>

          {/* Dedicated Fleet */}
          <div className="bg-gray-800 p-8 rounded-lg shadow-lg">
            <h2 className="text-3xl font-bold text-orange-600 mb-4">Dedicated Fleet Solutions</h2>
            <p className="text-gray-200 mb-4">
              Every business has unique logistics needs, which is why we offer dedicated fleet solutions. With a custom fleet at your disposal, you gain control and flexibility, allowing you to enhance your brand visibility and ensure your products reach their destinations in a reliable manner.
            </p>
            <p className="text-gray-200">
              TruckForce's dedicated fleet services are designed to give you full control over your shipments, with branding options available to promote your business on the road. By integrating our fleet into your supply chain, you enjoy seamless logistics management, increased efficiency, and the confidence that your products are in expert hands.
            </p>
          </div>

          {/* Real-Time Tracking */}
          <div className="bg-gray-800 p-8 rounded-lg shadow-lg">
            <h2 className="text-3xl font-bold text-orange-600 mb-4">Real-Time Tracking & Transparency</h2>
            <p className="text-gray-200 mb-4">
              Transparency is a fundamental part of TruckForce's service. We believe you should have full visibility of your shipments from start to finish. Our real-time tracking systems enable you to monitor each stage of your delivery, providing peace of mind and the ability to make informed decisions.
            </p>
            <p className="text-gray-200">
              With accurate ETA updates, live tracking, and instant alerts, you and your customers are always in the know. TruckForce empowers you with the tools and data you need to optimize scheduling, improve customer satisfaction, and keep your operations running smoothly.
            </p>
          </div>
        </div>
      </section>

      {/* Additional Services Section */}
      <section className="py-16 px-8 md:px-16 bg-gray-900">
        <h2 className="text-4xl font-bold text-center text-orange-600 mb-12" style={{ fontFamily: 'Lobster, sans-serif' }}>Additional Services</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-12">
          {/* Warehousing Solutions */}
          <div className="bg-gray-800 p-8 rounded-lg shadow-lg">
            <h3 className="text-3xl font-bold text-orange-600 mb-4">Warehousing Solutions</h3>
            <p className="text-gray-200">
              We offer secure, climate-controlled warehousing options to support the storage and distribution needs of businesses across industries. Our strategically located warehouses enable you to manage inventory efficiently, reduce lead times, and ensure quick, reliable delivery to your customers. From short-term storage to long-term inventory management, TruckForce provides flexible solutions to fit your specific requirements.
            </p>
          </div>

          {/* Last Mile Delivery */}
          <div className="bg-gray-800 p-8 rounded-lg shadow-lg">
            <h3 className="text-3xl font-bold text-orange-600 mb-4">Last Mile Delivery</h3>
            <p className="text-gray-200">
              Our last-mile delivery solutions are designed to bring your products directly to your customers with speed and accuracy. We understand that the final leg of delivery is crucial to customer satisfaction, and our team works diligently to ensure a seamless, timely experience. From urban areas to remote locations, TruckForce’s last-mile delivery service is geared to meet the expectations of today’s demanding market.
            </p>
          </div>
        </div>
      </section>

      {/* Contact CTA */}
      <section className="py-16 px-8 md:px-16 bg-gray-800 text-gray-200 text-center">
        <h2 className="text-3xl font-bold mb-4">Ready to Elevate Your Logistics?</h2>
        <p className="text-lg mb-6">Reach out to TruckForce for a consultation on how we can streamline your supply chain.</p>
        <a href="/logistics/contact" className="bg-orange-600 text-gray-200 px-6 py-3 rounded-lg font-medium hover:bg-orange-700 transition">
          Contact Us
        </a>
      </section>
    </div>
  );
};

export default LogisticsServices;
