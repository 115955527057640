import React from 'react';
import FurnitureNavBar from './FurnitureNavBar';
import heroImage1 from '../../assets/furniture/wood1.jpg';
import heroImage2 from '../../assets/furniture/wood2.jpg';
import heroImage3 from '../../assets/furniture/wood3.jpg';
import heroImage4 from '../../assets/furniture/wood4.jpg';
import heroImage5 from '../../assets/furniture/wood5.jpg';
import heroImage6 from '../../assets/furniture/wood6.jpg';
import heroImage7 from '../../assets/furniture/wood8.jpg';

import portfolioImage1 from '../../assets/furniture/wood10.jpg';
import portfolioImage2 from '../../assets/furniture/wood9.jpg';
import portfolioImage3 from '../../assets/furniture/wood7.jpg';

const FurnitureHome = () => {
  const images = [heroImage1, heroImage2, heroImage3, heroImage4, heroImage5, heroImage6, heroImage7];

  const portfolioItems = [
    {
      image: portfolioImage1,
      title: 'Rustic Dining Table',
      description: 'Crafted from reclaimed wood, this dining table merges strength and beauty, showcasing rich textures and natural imperfections that tell a story. Each plank was hand-selected to bring out an organic, rustic charm, making it the centerpiece of any dining space. Finished with a high-quality matte sealant, it offers durability while preserving the wood’s original character.'
    },
    {
      image: portfolioImage2,
      title: 'Elegant Bookshelf',
      description: 'Inspired by minimalist Scandinavian design, this elegant bookshelf features smooth edges and a flawless finish. The open shelving offers versatile storage, perfect for showcasing books, decorative items, and plants. Crafted from sustainably sourced oak, it brings a warm and inviting ambiance to any room. Each joint was crafted with precision, reflecting TimberWorks’ dedication to quality.'
    },
    {
      image: portfolioImage3,
      title: 'Handcrafted Wooden Chair',
      description: 'This handcrafted wooden chair blends comfort with elegance. Made from sturdy walnut, it offers a smooth, ergonomic backrest that supports hours of relaxation. The chair’s natural finish and clean lines make it adaptable to both traditional and modern settings. Each curve and angle was meticulously carved to create a functional yet beautiful piece that speaks of lasting quality.'
    },
  ];

  const testimonials = [
    {
      quote: "We couldn’t be happier with our new dining table. The craftsmanship is impeccable, and the table truly brings warmth and character to our home. TimberWorks exceeded our expectations, from the quality of the wood to the attention to every small detail.",
      client: "Olivia M."
    },
    {
      quote: "I’ve always wanted a custom-made bookshelf, and TimberWorks made my dream come true. They listened to my design preferences and created a piece that fits perfectly in my living room. The wood grain is stunning, and it’s clearly built to last for generations.",
      client: "James T."
    },
    {
      quote: "The wooden chair I purchased from TimberWorks is not just a piece of furniture; it’s a work of art. You can see and feel the quality in every inch. It’s comfortable, sturdy, and has quickly become my favorite place to sit and relax.",
      client: "Emily S."
    }
  ];

  return (
    <div className="bg-gray-100 text-gray-800 font-sans">
      <FurnitureNavBar />
      
      {/* Hero Bento Section */}
      <section className="relative h-screen p-8 grid grid-areas-bento grid-rows-4 grid-cols-4 gap-4">
        
        {/* Bento Items with varied sizes */}
        <div 
          className="bg-cover bg-center col-span-2 row-span-2"
          style={{ backgroundImage: `url(${images[0]})` }}
        />
        <div 
          className="bg-cover bg-center col-span-1 row-span-2"
          style={{ backgroundImage: `url(${images[1]})` }}
        />
        <div 
          className="bg-cover bg-center col-span-1 row-span-1"
          style={{ backgroundImage: `url(${images[2]})` }}
        />
        <div 
          className="bg-cover bg-center col-span-1 row-span-1"
          style={{ backgroundImage: `url(${images[5]})` }}
        />
        
        <div 
          className="bg-cover bg-center col-span-2 row-span-1"
          style={{ backgroundImage: `url(${images[3]})` }}
        />
        
        <div 
          className="bg-cover bg-center col-span-2 row-span-2"
          style={{ backgroundImage: `url(${images[4]})` }}
        />
        <div 
          className="bg-cover bg-center col-span-2 row-span-1"
          style={{ backgroundImage: `url(${images[6]})` }}
        />
        
      </section>

      {/* About Us Preview */}
      {/* About Us Preview */}
<section className="py-16 px-8 md:px-16 bg-gray-100">
  <h2 
    className="text-3xl font-bold mb-6 text-center" 
    style={{ fontFamily: 'Cormorant Garamond, serif' }}
  >
    TimberWorks Artisan Woodcraft
  </h2>
  <p 
    className="text-gray-800 text-lg text-center max-w-3xl mx-auto mb-6" 
    style={{ fontFamily: 'Cormorant Garamond, serif', fontWeight: 300 }}
  >
    Our work is more than just furniture – it’s a journey of artistry, tradition, and innovation. From each carefully chosen piece of wood to the final polish, every step is infused with a profound respect for nature's beauty and a commitment to craft timeless, one-of-a-kind pieces.
  </p>
  <p 
    className="text-gray-800 text-lg text-center max-w-3xl mx-auto mb-6" 
    style={{ fontFamily: 'Cormorant Garamond, serif' }}
  >
    <span className="font-semibold">"Crafting Stories Through Wood"</span> is not just a motto but a promise. Each piece tells a unique story, bringing character and warmth to any space. We embrace the individuality of each grain, knot, and hue, working with precision and passion to transform these elements into heirlooms that become part of your story.
  </p>
  <p 
    className="text-gray-800 text-lg text-center max-w-3xl mx-auto mb-6" 
    style={{ fontFamily: 'Cormorant Garamond, serif', fontWeight: 300 }}
  >
    Our handcrafted creations are born from a blend of traditional woodworking techniques and modern design. Driven by a passion for sustainability and a dedication to quality, we source our materials ethically and work meticulously to bring you furniture that transcends trends, offering enduring beauty and functionality.
  </p>
  <p 
    className="text-gray-800 text-lg text-center max-w-3xl mx-auto" 
    style={{ fontFamily: 'Cormorant Garamond, serif' }}
  >
    Discover the artistry and soul behind each handcrafted piece, and let it inspire a new chapter in your home’s story. Whether it’s a rustic dining table, a minimalist bookshelf, or a custom piece made to your specifications, our creations are crafted to make lasting impressions.
  </p>
</section>


      {/* Portfolio Preview */}
      <section className="py-16 px-8 md:px-16 bg-gray-200">
        <h2 className="text-3xl font-bold mb-6 text-center text-gray-800" style={{ fontFamily: 'Cormorant Garamond, serif' }}>
          Our Creations
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {portfolioItems.map((item, index) => (
            <div key={index} className="bg-white rounded-lg shadow-lg overflow-hidden">
              <img src={item.image} alt={item.title} className="w-full h-56 object-cover" />
              <div className="p-4" style={{ fontFamily: 'Cormorant Garamond, serif' }}>
                <h3 className="text-xl font-semibold text-gray-800">{item.title}</h3>
                <p className="text-gray-600 mt-2">{item.description}</p>
              </div>
            </div>
          ))}
        </div>
      </section>

      {/* Testimonials */}
      <section className="py-16 px-8 md:px-16 bg-gray-100">
        <h2 className="text-3xl font-bold mb-6 text-center text-gray-800" style={{ fontFamily: 'Cormorant Garamond, serif' }}>
          What Our Clients Say
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {testimonials.map((testimonial, index) => (
            <div key={index} className="bg-gray-200 rounded-lg p-6 shadow-lg" style={{ fontFamily: 'Cormorant Garamond, serif' }}>
              <p className="italic text-gray-800">"{testimonial.quote}"</p>
              <p className="mt-4 text-right font-bold text-gray-800">- {testimonial.client}</p>
            </div>
          ))}
        </div>
      </section>

      {/* Contact CTA */}
      <section className="py-16 px-8 md:px-16 bg-gray-800 text-white text-center">
        <h2 className="text-3xl font-bold mb-4" style={{ fontFamily: 'Cormorant Garamond, serif' }}>
          Interested in Custom Work?
        </h2>
        <p className="text-lg mb-6" style={{ fontFamily: 'Cormorant Garamond, serif', fontWeight: 300 }}>
          Contact us to start designing your unique piece of furniture.
        </p>
        <a href="/furniture/contact" className="bg-white text-gray-800 px-6 py-3 rounded-lg font-medium hover:bg-gray-100 transition" style={{ fontFamily: 'Cormorant Garamond, serif' }}>
          Get In Touch
        </a>
      </section>
    </div>
  );
};

export default FurnitureHome;
