import React from "react";
import FoodNavBar from "./FoodNavBar";
import tileBackgroundImage from '../../assets/food/tile.webp';
import tileBackgroundImage1 from '../../assets/food/bbc3.webp'; // Import the tiled background image

const FoodContact = () => {
  return (
    <div className="min-h-screen bg-[#F5F1ED] text-gray-900 font-sans">
      <FoodNavBar />
      
      <header 
        className="relative bg-[#502419] text-white p-6 text-center border-4 border-[#D72638] mb-8"
        style={{ backgroundImage: `url(${tileBackgroundImage})`, backgroundSize: 'cover' }}
      >
        <h1 className="text-5xl font-bold" style={{ fontFamily: 'Pacifico, cursive' }}>Contact Us</h1>
      </header>

      <main className="p-6 max-w-screen-lg mx-auto" style={{ backgroundImage: `url(${tileBackgroundImage1})`, backgroundSize: 'cover' }}>
        
        <section className="my-8">
          <h2 className="text-4xl font-semibold mb-4" style={{ fontFamily: 'Pacifico, cursive' }}>
            <span className="bg-[#F6C90E] p-2 rounded-lg">Get in Touch</span>
          </h2>
          <div className="bg-[#D3D3D3] text-[#502419] p-6 rounded-lg shadow-lg border-4 border-[#1687A7] relative">
            <div className="absolute inset-0 border-8 border-ridge border-[#D72638] rounded-lg -m-2" />
            <p className="text-lg mb-4">
              We would love to hear from you! Whether you have questions about our beers, want to 
              schedule a tour, or simply want to say hello, feel free to reach out using the contact 
              form below or through our contact details.
            </p>
            <p className="text-lg mb-4"><strong>Email:</strong> contact@bubblybrewco.com</p>
            <p className="text-lg mb-4"><strong>Phone:</strong> (123) 456-7890</p>
            <p className="text-lg mb-4"><strong>Address:</strong> 123 Brew Street, Beer City, BC 12345</p>
          </div>
        </section>

        <section className="my-8">
          <h2 className="text-4xl font-semibold mb-4" style={{ fontFamily: 'Pacifico, cursive' }}>
            <span className="bg-[#F6C90E] p-2 rounded-lg">Contact Form</span>
          </h2>
          <div className="bg-[#D3D3D3] text-[#502419] p-6 rounded-lg shadow-lg border-4 border-[#1687A7] relative">
            <div className="absolute inset-0 border-8 border-ridge border-[#D72638] rounded-lg -m-2" />
            <form className="space-y-4">
              <div>
                <label className="block text-lg mb-2" htmlFor="name">Name:</label>
                <input 
                  type="text" 
                  id="name" 
                  className="w-full p-2 border border-[#1687A7] rounded-lg" 
                  placeholder="Your Name" 
                  required 
                />
              </div>
              <div>
                <label className="block text-lg mb-2" htmlFor="email">Email:</label>
                <input 
                  type="email" 
                  id="email" 
                  className="w-full p-2 border border-[#1687A7] rounded-lg" 
                  placeholder="Your Email" 
                  required 
                />
              </div>
              <div>
                <label className="block text-lg mb-2" htmlFor="message">Message:</label>
                <textarea 
                  id="message" 
                  className="w-full p-2 border border-[#1687A7] rounded-lg" 
                  rows="4" 
                  placeholder="Your Message" 
                  required 
                />
              </div>
              <button 
                type="submit" 
                className="bg-[#F6C90E] text-black px-4 py-2 rounded-full font-bold hover:bg-[#1687A7] transition transform hover:-translate-y-1"
              >
                Send Message
              </button>
            </form>
          </div>
        </section>
      </main>
    </div>
  );
};

export default FoodContact;
