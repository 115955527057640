import React from "react";
import LegalNavBar from "./LegalNavBar";

const LegalContact = () => {
  return (
    <div className="min-h-screen bg-slate-800 text-slate-200 font-sans">
      <LegalNavBar />

      {/* Header Section */}
      <header className="bg-slate-900 text-white py-12 text-center">
        <h1 className="text-5xl font-serif font-bold text-teal-400">
          Contact Us
        </h1>
        <p className="text-lg mt-4 max-w-3xl mx-auto text-slate-300">
          Reach out to our team for any inquiries, consultations, or further
          assistance. We're here to help you navigate your legal journey with
          confidence.
        </p>
      </header>

      {/* Main Content */}
      <main className="py-16 px-8 max-w-screen-lg mx-auto space-y-16">
        {/* Team Contact Section */}
        <section className="space-y-8">
          <h2 className="text-4xl font-serif font-bold text-teal-400 mb-4">
            Contact Our Team
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {/* Contact Card for each team member */}
            {[
              {
                name: "John Doe",
                title: "Senior Attorney",
                email: "john.doe@legalfirm.com",
                phone: "+1 (555) 123-4567",
              },
              {
                name: "Jane Smith",
                title: "Family Law Specialist",
                email: "jane.smith@legalfirm.com",
                phone: "+1 (555) 987-6543",
              },
              {
                name: "Alice Brown",
                title: "Corporate Law Advisor",
                email: "alice.brown@legalfirm.com",
                phone: "+1 (555) 456-7890",
              },
            ].map((member, index) => (
              <div
                key={index}
                className="bg-slate-700 p-6 rounded-lg shadow-lg text-center"
              >
                <h3 className="text-2xl font-serif font-bold text-teal-400 mb-2">
                  {member.name}
                </h3>
                <p className="text-slate-300">{member.title}</p>
                <p className="text-slate-400 mt-4">
                  <strong>Email:</strong> <a href={`mailto:${member.email}`} className="text-teal-400 hover:underline">{member.email}</a>
                </p>
                <p className="text-slate-400">
                  <strong>Phone:</strong> <a href={`tel:${member.phone}`} className="text-teal-400 hover:underline">{member.phone}</a>
                </p>
              </div>
            ))}
          </div>
        </section>

        {/* Contact Form Section */}
        <section className="bg-slate-700 p-8 rounded-lg shadow-md">
          <h2 className="text-3xl font-serif font-bold text-teal-400 mb-6">
            General Inquiries
          </h2>
          <p className="text-slate-300 mb-6">
            Use the form below to send us a message, and a member of our team
            will get back to you promptly.
          </p>
          <form className="space-y-4">
            <div>
              <label className="block text-slate-300 mb-2">Name:</label>
              <input
                type="text"
                className="w-full p-3 border border-slate-600 rounded bg-slate-800 text-slate-200 focus:outline-none focus:border-teal-400"
                placeholder="Your Name"
              />
            </div>
            <div>
              <label className="block text-slate-300 mb-2">Email:</label>
              <input
                type="email"
                className="w-full p-3 border border-slate-600 rounded bg-slate-800 text-slate-200 focus:outline-none focus:border-teal-400"
                placeholder="Your Email"
              />
            </div>
            <div>
              <label className="block text-slate-300 mb-2">Message:</label>
              <textarea
                className="w-full p-3 border border-slate-600 rounded bg-slate-800 text-slate-200 focus:outline-none focus:border-teal-400"
                rows="5"
                placeholder="Your Message"
              ></textarea>
            </div>
            <button
              type="submit"
              className="mt-4 bg-white text-slate-900 py-2 px-6 rounded-lg font-medium hover:bg-teal-500 hover:text-slate-900 transition"
            >
              Submit
            </button>
          </form>
        </section>
      </main>
    </div>
  );
};

export default LegalContact;
