import React, { useState, useEffect } from 'react';
import ECommerceNavBar from './ECommerceNavBar';
import heroImage from '../../assets/ecommerce/logo3.webp';
import heroImageSmall from '../../assets/ecommerce/logo4.webp'; // Small screen image
import parallaxImage1 from '../../assets/ecommerce/graf1.webp';
import parallaxImage2 from '../../assets/ecommerce/graf2.webp';
import parallaxImage3 from '../../assets/ecommerce/graf3.webp';
import parallaxImage4 from '../../assets/ecommerce/graf4.webp';
import parallaxImage5 from '../../assets/ecommerce/graf5.webp';

const ECommerceHome = () => {
  const [isSmallScreen, setIsSmallScreen] = useState(false);

  useEffect(() => {
    // Function to check screen width
    const checkScreenSize = () => {
      setIsSmallScreen(window.innerWidth <= 640); // Small screen if width is 640px or less
    };
    
    // Initial check
    checkScreenSize();
    
    // Event listener to handle window resize
    window.addEventListener('resize', checkScreenSize);
    
    // Clean up event listener
    return () => window.removeEventListener('resize', checkScreenSize);
  }, []);

  return (
    <div className="bg-slate-900 text-gray-100 font-sans">
      <ECommerceNavBar />

      {/* Hero Section */}
      <section
        className="relative h-screen bg-cover bg-center"
        style={{
          backgroundImage: `url(${isSmallScreen ? heroImageSmall : heroImage})`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
        }}
      ></section>

      {/* Parallax Section 1 */}
      <section className="relative bg-fixed bg-cover bg-center h-screen" style={{ backgroundImage: `url(${parallaxImage1})` }}>
        <div className="absolute inset-0 bg-black opacity-25"></div>
        <div className="relative z-10 flex flex-col items-center justify-center h-full text-center px-6">
          <div className="bg-gray-800 bg-opacity-80 border border-yellow-500 rounded-lg p-8 max-w-xl">
            <h1 className="text-5xl font-bold mb-6 font-anton">Welcome to Graf Streetwear</h1>
            <p className="text-xl mb-8 font-oswald">
              Dive into the world of bold fashion where urban culture meets uncompromised style. Our streetwear isn’t just clothing; it’s a statement.
            </p>
            <a href="ecommerce/shop" className="bg-yellow-500 text-gray-900 px-8 py-3 mb-3 rounded-full font-semibold hover:bg-yellow-600 transition font-anton">
              Explore the Collection
            </a>
          </div>
        </div>
      </section>

      {/* Parallax Section 2 - Mission */}
      <section className="relative bg-fixed bg-cover bg-center h-screen" style={{ backgroundImage: `url(${parallaxImage2})` }}>
        <div className="absolute inset-0 bg-black opacity-25"></div>
        <div className="relative z-10 flex flex-col items-center justify-center h-full text-center px-6">
          <div className="bg-gray-800 bg-opacity-80 border border-pink-600 rounded-lg p-8 max-w-lg">
            <h2 className="text-4xl font-bold text-yellow-500 mb-4 font-anton">Our Mission</h2>
            <p className="text-lg mb-6 text-gray-200 font-oswald">
              At Graf Streetwear, our mission is to redefine streetwear, fusing style with the raw energy of the urban landscape. Each piece is crafted to empower and inspire those who wear it, celebrating bold self-expression.
            </p>
          </div>
        </div>
      </section>

      {/* Parallax Section 3 - Exclusive Designs */}
      <section className="relative bg-fixed bg-cover bg-center h-screen" style={{ backgroundImage: `url(${parallaxImage3})` }}>
        <div className="absolute inset-0 bg-black opacity-25"></div>
        <div className="relative z-10 flex flex-col items-center justify-center h-full text-center px-6">
          <div className="bg-gray-800 bg-opacity-80 border border-yellow-500 rounded-lg p-8 max-w-lg">
            <h2 className="text-4xl font-bold text-pink-600 mb-4 font-anton">Exclusive Designs</h2>
            <p className="text-lg mb-6 text-gray-200 font-oswald">
              Graf Streetwear’s collections are more than fashion—they are works of art. Designed in limited editions, our apparel reflects the creativity and fearlessness of the street, allowing you to own something uniquely yours.
            </p>
          </div>
        </div>
      </section>

      {/* Parallax Section 4 - Inspiration */}
      <section className="relative bg-fixed bg-cover bg-center h-screen" style={{ backgroundImage: `url(${parallaxImage4})` }}>
        <div className="absolute inset-0 bg-black opacity-25"></div>
        <div className="relative z-10 flex flex-col items-center justify-center h-full text-center px-6">
          <div className="bg-gray-800 bg-opacity-80 border border-blue-700 rounded-lg p-8 max-w-lg">
            <h2 className="text-4xl font-bold text-blue-700 mb-4 font-anton">Our Inspiration</h2>
            <p className="text-lg mb-6 text-gray-200 font-oswald">
              Each piece we create is inspired by the heartbeat of the city—graffiti, music, art, and the voices of those who shape urban culture. We draw inspiration from the grit and the soul that define the streets.
            </p>
          </div>
        </div>
      </section>

      {/* Parallax Section 5 - Quality Craftsmanship */}
      <section className="relative bg-fixed bg-cover bg-center h-screen" style={{ backgroundImage: `url(${parallaxImage5})` }}>
        <div className="absolute inset-0 bg-black opacity-25"></div>
        <div className="relative z-10 flex flex-col items-center justify-center h-full text-center px-6">
          <div className="bg-gray-800 bg-opacity-80 border border-pink-600 rounded-lg p-8 max-w-lg">
            <h2 className="text-4xl font-bold text-pink-600 mb-4 font-anton">Quality Craftsmanship</h2>
            <p className="text-lg mb-6 text-gray-200 font-oswald">
              At Graf Streetwear, quality is as important as style. Every garment is crafted from premium materials to ensure durability and comfort, making sure you can wear your boldest statement time and again.
            </p>
            <a href="ecommerce/contact" className="bg-yellow-500 text-gray-900 px-8 py-3 rounded-full font-semibold hover:bg-yellow-600 transition font-anton">
              Join the Movement
            </a>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ECommerceHome;
