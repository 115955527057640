import React from "react";
import { Link } from "react-router-dom";

const ECommerceNavBar = () => (
  <nav className="bg-slate-900 text-white p-5 flex justify-center space-x-8 font-semibold font-anton">
    <Link to="/ecommerce" className="text-yellow-500 hover:text-yellow-400 transition duration-200">Home</Link>
    <Link to="/ecommerce/shop" className="text-pink-600 hover:text-pink-500 transition duration-200">Shop</Link>
    <Link to="/ecommerce/about" className="text-blue-700 hover:text-blue-600 transition duration-200">About Us</Link>
    <Link to="/ecommerce/contact" className="text-gray-400 hover:text-gray-200 transition duration-200">Contact</Link>
  </nav>
);

export default ECommerceNavBar;
