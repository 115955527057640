import React from "react";
import { Link } from "react-router-dom";

const FoodNavBar = () => (
  <nav className="bg-[#F6C90E] text-[#502419] p-4 flex justify-center space-x-6" style={{ fontFamily: 'Pacifico, cursive' }}>
    <Link to="/food" className="hover:underline hover:text-[#1687A7] transition duration-300">Home</Link>
    <Link to="/food/about" className="hover:underline hover:text-[#1687A7] transition duration-300">About Us</Link>
    <Link to="/food/events" className="hover:underline hover:text-[#1687A7] transition duration-300">Events</Link>
    <Link to="/food/contact" className="hover:underline hover:text-[#1687A7] transition duration-300">Contact</Link>
  </nav>
);

export default FoodNavBar;
