import React from 'react';
import { motion } from 'framer-motion';
import HealthcareNavBar from './HealthcareNavBar';

const HealthcareContact = () => {
  return (
    <div className="bg-gray-50 text-gray-900 font-lora">
      <HealthcareNavBar />

      {/* Header Section */}
      <section className="py-16 px-8 md:px-16 bg-blue-800 text-white text-center">
        <h1 className="text-4xl font-bold mb-4">Contact Us</h1>
        <p className="text-lg max-w-3xl mx-auto">
          We’d love to hear from you! Whether you have questions about our services, want to schedule an appointment, or need assistance, our team is here to help.
        </p>
      </section>

      {/* Contact Information and Form */}
      <section className="py-16 px-8 md:px-16 bg-gray-100">
        <div className="max-w-4xl mx-auto grid grid-cols-1 md:grid-cols-2 gap-12 items-center">
          {/* Contact Information */}
          <motion.div
            className="space-y-6"
            initial={{ opacity: 0, x: -50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.6 }}
          >
            <h2 className="text-3xl font-bold text-blue-800 mb-4">Get in Touch</h2>
            <p className="text-gray-700 text-lg">
              <strong>Address:</strong> 123 Smile St., Dentalville, DV 45678
            </p>
            <p className="text-gray-700 text-lg">
              <strong>Phone:</strong> (555) 123-4567
            </p>
            <p className="text-gray-700 text-lg">
              <strong>Email:</strong> contact@smilesdental.com
            </p>
            <p className="text-gray-700 text-lg">
              <strong>Hours:</strong>
              <br />
              Monday - Friday: 9:00 AM - 6:00 PM
              <br />
              Saturday: 9:00 AM - 2:00 PM
              <br />
              Sunday: Closed
            </p>
          </motion.div>

          {/* Contact Form */}
          <motion.div
            className="bg-white p-6 rounded-lg shadow-lg"
            initial={{ opacity: 0, x: 50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.6, delay: 0.2 }}
          >
            <h2 className="text-2xl font-bold text-blue-800 mb-4">Send Us a Message</h2>
            <form>
              <div className="mb-4">
                <label className="block text-gray-700 font-medium mb-1" htmlFor="name">Name</label>
                <input type="text" id="name" className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring focus:ring-blue-300" placeholder="Your Name" />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 font-medium mb-1" htmlFor="email">Email</label>
                <input type="email" id="email" className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring focus:ring-blue-300" placeholder="Your Email" />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 font-medium mb-1" htmlFor="phone">Phone</label>
                <input type="tel" id="phone" className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring focus:ring-blue-300" placeholder="Your Phone Number" />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 font-medium mb-1" htmlFor="message">Message</label>
                <textarea id="message" rows="4" className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring focus:ring-blue-300" placeholder="Your Message"></textarea>
              </div>
              <button type="submit" className="w-full bg-yellow-400 text-gray-900 px-6 py-3 rounded-lg font-medium hover:bg-yellow-500 transition">
                Send Message
              </button>
            </form>
          </motion.div>
        </div>
      </section>

      {/* Location Map or Placeholder Image */}
      

      <section className="py-16 px-8 md:px-16 bg-white text-center">
      <h2 className="text-3xl font-bold mb-6">Find Us Here</h2>
        <iframe
          title="Graf Streetwear Location"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3151.8354345093745!2d144.95373511565353!3d-37.817209742105224!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6ad642af0f11fd81%3A0xf57708c5e5d4d5e6!2sFederation%20Square!5e0!3m2!1sen!2sus!4v1637916375307!5m2!1sen!2sus"
          width="100%"
          height="400"
          className="rounded-lg shadow-lg"
          allowFullScreen=""
          loading="lazy"
        ></iframe>
      </section>

      
    </div>
  );
};

export default HealthcareContact;
