import React, { useEffect, useRef } from "react";
import * as THREE from "three";

const RotatingSphere = () => {
  const mountRef = useRef(null);
  let scene, camera, renderer, sphere, directionalLight, lightTarget;

  useEffect(() => {
    // Set up the scene, camera, and renderer
    scene = new THREE.Scene();
    camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000);
    renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true });
    renderer.setSize(window.innerWidth, window.innerHeight);
    if (mountRef.current) {
      mountRef.current.appendChild(renderer.domElement);
    }

    // Create a larger purple metallic sphere
    const geometry = new THREE.SphereGeometry(3, 64, 64); // Large radius
    const material = new THREE.MeshStandardMaterial({
      color: 0x800080, // Purple color
      metalness: 0.99, // Full metallic
      roughness: 0.1, // Smooth surface
    });

    sphere = new THREE.Mesh(geometry, material);
    sphere.position.set(0, 0, 0); // Center the sphere
    scene.add(sphere);

    // Set up strong directional light positioned outside the sphere
    directionalLight = new THREE.DirectionalLight(0x830744, 5000); // Color, intensity
    directionalLight.position.set(5, 5, 10); // Move it outside the sphere

    // Create a target object for the light to point at the sphere
    lightTarget = new THREE.Object3D();
    lightTarget.position.set(0, 0, 0); // Position it at the center of the sphere
    scene.add(lightTarget);

    directionalLight.target = lightTarget; // Ensure the light points at the sphere
    scene.add(directionalLight);

    // Add ambient light for overall illumination
    const ambientLight = new THREE.AmbientLight(0xffffff, 0.3); // Soft ambient light
    scene.add(ambientLight);

    camera.position.z = 10; // Move the camera back for better view of the sphere

    // Animate the sphere and light on scroll
    const handleScroll = () => {
      const scrollY = window.scrollY;

      // Move the sphere in the opposite direction of the scroll to create a fixed effect
      sphere.position.y = -scrollY * 0.01; // Adjust the multiplier for speed

      // Rotate the light around the sphere based on scroll
      const radius = 10; // Distance from the sphere
      directionalLight.position.x = radius * Math.sin(scrollY * 0.01); // Rotate around x
      directionalLight.position.y = radius * Math.cos(scrollY * 0.01); // Rotate around y
      directionalLight.position.z = radius * Math.cos(scrollY * 0.01); // Rotate around y

      // Update the target position to keep pointing at the sphere
      directionalLight.target.position.copy(lightTarget.position);

      renderer.render(scene, camera);
    };

    window.addEventListener("scroll", handleScroll);
    window.addEventListener("resize", () => {
      // Update camera aspect ratio and renderer size on window resize
      camera.aspect = window.innerWidth / window.innerHeight;
      camera.updateProjectionMatrix();
      renderer.setSize(window.innerWidth, window.innerHeight);
    });

    // Clean up on unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
      if (mountRef.current && renderer.domElement) {
        mountRef.current.removeChild(renderer.domElement);
      }
    };
  }, []);

  return <div ref={mountRef} style={{ height: "100vh", overflow: "hidden" }} />;
};

export default RotatingSphere;
