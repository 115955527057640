import React from "react";
import FoodNavBar from "./FoodNavBar";
import tileBackgroundImage from '../../assets/food/tile.webp';
import tileBackgroundImage1 from '../../assets/food/bbc3.webp'; // Import the tiled background image
import team1 from '../../assets/food/team1.webp';
import team2 from '../../assets/food/team2.webp';
import team3 from '../../assets/food/team3.webp';


const FoodAbout = () => {
  return (
    <div className="min-h-screen bg-[#F5F1ED] text-gray-900 font-sans">
      <FoodNavBar />
      
      <header 
        className="relative bg-[#502419] text-white p-6 text-center border-4 border-[#D72638] mb-8"
        style={{ backgroundImage: `url(${tileBackgroundImage})`, backgroundSize: 'cover' }}
      >
        <h1 className="text-5xl font-bold" style={{ fontFamily: 'Pacifico, cursive' }}>Bubbly Brew Co.</h1>
      </header>

      <main className="p-6 max-w-screen-lg mx-auto" style={{ backgroundImage: `url(${tileBackgroundImage1})`, backgroundSize: 'cover' }}>
        <section className="my-8">
          <h2 className="text-4xl font-semibold mb-4" style={{ fontFamily: 'Pacifico, cursive' }}>
            <span className="bg-[#F6C90E] p-2 rounded-lg">Our Story</span>
          </h2>
          <div className="bg-[#D3D3D3] text-[#502419] p-6 rounded-lg shadow-lg border-4 border-[#1687A7] relative"> {/* Soft background color */}
            <div className="absolute inset-0 border-8 border-ridge border-[#D72638] rounded-lg -m-2" /> {/* Patterned border */}
            <p className="text-lg mb-4">
              At Bubbly Brew Co., we believe that craft beer is more than just a drink; it's a lifestyle. 
              Founded by a group of passionate brewers, we set out on a journey to create unique flavors that 
              reflect our love for the art of brewing. Our beers are crafted using the finest ingredients and 
              brewed with heart, ensuring that every sip is a celebration of flavor and creativity.
            </p>
            <p className="text-lg mb-4">
              Whether you prefer a hoppy IPA, a rich stout, or a refreshing lager, we have something to 
              tantalize your taste buds. Our mission is to push the boundaries of traditional brewing 
              while honoring the time-honored methods that make craft beer so special.
            </p>
            <p className="text-lg mb-4">
              Join us at our brewery to experience the magic for yourself. We offer tours, tastings, and 
              events that celebrate the vibrant community of craft beer enthusiasts. Let's raise a glass to 
              the good times, the great flavors, and the unforgettable moments shared over a cold brew!
            </p>
          </div>
        </section>
        
        <section className="my-8">
          <h2 className="text-4xl font-semibold mb-4" style={{ fontFamily: 'Pacifico, cursive' }}>
            <span className="bg-[#F6C90E] p-2 rounded-lg">Our Values</span>
          </h2>
          <div className="bg-[#D3D3D3] text-[#502419] p-6 rounded-lg shadow-lg border-4 border-[#1687A7] relative">
            <div className="absolute inset-0 border-8 border-ridge border-[#D72638] rounded-lg -m-2" /> {/* Patterned border */}
            <p className="text-lg mb-4">
              At Bubbly Brew Co., our values guide us in everything we do:
            </p>
            <ul className="list-disc list-inside text-lg mb-4">
              <li><strong>Quality:</strong> We prioritize quality in every batch we brew.</li>
              <li><strong>Community:</strong> We are dedicated to supporting our local community.</li>
              <li><strong>Sustainability:</strong> We strive to use sustainable practices in our brewing process.</li>
              <li><strong>Innovation:</strong> We love experimenting with new flavors and techniques.</li>
            </ul>
          </div>
        </section>
        
        <section className="my-8">
  <h2 className="text-4xl font-semibold mb-4" style={{ fontFamily: 'Pacifico, cursive' }}>
    <span className="bg-[#F6C90E] p-2 rounded-lg">Meet Our Team</span>
  </h2>
  <div className="bg-[#D3D3D3] text-[#502419] p-6 rounded-lg shadow-lg border-4 border-[#1687A7] relative">
    <div className="absolute inset-0 border-8 border-ridge border-[#D72638] rounded-lg -m-2" /> {/* Patterned border */}
    <p className="text-lg mb-4">
      Our team of passionate brewers, chefs, and craft beer aficionados work tirelessly to bring you 
      the best craft beer experience. We believe in collaboration and creativity, which is why we 
      invite local artists and musicians to join us in our space.
    </p>
    <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
      <div className="h-40 rounded-lg overflow-hidden shadow-lg">
        <img src={team1} alt="Team Member 1" className="object-cover w-full h-full" />
      </div>
      <div className="h-40 rounded-lg overflow-hidden shadow-lg">
        <img src={team2} alt="Team Member 2" className="object-cover w-full h-full" />
      </div>
      <div className="h-40 rounded-lg overflow-hidden shadow-lg">
        <img src={team3} alt="Team Member 3" className="object-cover w-full h-full" />
      </div>
    </div>
  </div>
</section>
      </main>
    </div>
  );
};

export default FoodAbout;
