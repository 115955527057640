import React from 'react';
import { motion } from 'framer-motion';
import HealthcareNavBar from './HealthcareNavBar';
import teamImage from '../../assets/healthcare/hca4.jpg';
import historyImage from '../../assets/healthcare/hca1.jpg';
import missionImage from '../../assets/healthcare/hca3.jpg';
import valuesImage from '../../assets/healthcare/hca2.jpg';

const HealthcareAbout = () => {
  const sectionVariants = {
    hidden: { opacity: 0, x: -50 },
    visible: { opacity: 1, x: 0 }
  };

  return (
    <div className="bg-gray-50 text-gray-900 font-lora">
      <HealthcareNavBar />

      {/* Header Section */}
      <section className="py-16 px-8 md:px-16 bg-blue-800 text-white text-center">
        <h1 className="text-4xl font-bold mb-4">About Smiles Dental</h1>
        <p className="text-lg max-w-3xl mx-auto">
          Discover the story behind Smiles Dental, our mission, and the values that guide us as we care for our patients.
          At Smiles Dental, we’re committed to making dental visits a positive, comfortable, and enriching experience for everyone.
        </p>
      </section>

      {/* Our Story Section */}
      <section className="py-16 px-8 md:px-16 bg-gray-100">
        <motion.div
          className="max-w-4xl mx-auto flex flex-col md:flex-row items-center gap-8"
          variants={sectionVariants}
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.5 }}
          transition={{ duration: 0.6 }}
        >
          <img src={historyImage} alt="Our History" className="rounded-lg w-full h-64 object-cover md:w-1/2" />
          <div className="md:w-1/2">
            <h2 className="text-3xl font-bold text-blue-800 mb-4">Our Story</h2>
            <p className="text-gray-700 text-lg mb-4 leading-relaxed">
              Smiles Dental was founded over 20 years ago with a vision to bring high-quality, compassionate dental care to our community.
              From humble beginnings, our practice has grown into a full-service dental clinic, trusted by thousands of families and individuals.
              We started with a simple idea: to make dental visits less daunting and more enjoyable by focusing on comfort, trust, and personalized care.
            </p>
            <p className="text-gray-700 text-lg leading-relaxed">
              Over the years, our team has expanded, bringing together skilled professionals who share a passion for dental health and a commitment to our patients.
              Every step of our journey has been driven by the relationships we build with our patients and the smiles we help create.
            </p>
          </div>
        </motion.div>
      </section>

      {/* Mission Section */}
      <section className="py-16 px-8 md:px-16 bg-white">
        <motion.div
          className="max-w-4xl mx-auto flex flex-col md:flex-row items-center gap-8"
          variants={sectionVariants}
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.5 }}
          transition={{ duration: 0.6, delay: 0.2 }}
        >
          <div className="md:w-1/2">
            <h2 className="text-3xl font-bold text-blue-800 mb-4">Our Mission</h2>
            <p className="text-gray-700 text-lg mb-4 leading-relaxed">
              Our mission at Smiles Dental is to provide comprehensive dental care that not only enhances our patients’ oral health but also enriches their lives.
              We believe that a healthy smile has the power to boost confidence, improve quality of life, and make a lasting impression.
            </p>
            <p className="text-gray-700 text-lg leading-relaxed">
              Through a personalized approach to dentistry, we work closely with each patient to understand their unique needs and preferences.
              Our goal is to deliver care that goes beyond dental treatment—care that educates, empowers, and leaves a positive impact on our patients' lives.
            </p>
          </div>
          <img src={missionImage} alt="Our Mission" className="rounded-lg w-full h-64 object-cover md:w-1/2" />
        </motion.div>
      </section>

      {/* Values Section */}
      <section className="py-16 px-8 md:px-16 bg-gray-100">
        <motion.div
          className="max-w-4xl mx-auto flex flex-col md:flex-row items-center gap-8"
          variants={sectionVariants}
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.5 }}
          transition={{ duration: 0.6, delay: 0.4 }}
        >
          <img src={valuesImage} alt="Our Values" className="rounded-lg w-full h-64 object-cover md:w-1/2" />
          <div className="md:w-1/2">
            <h2 className="text-3xl font-bold text-blue-800 mb-4">Our Values</h2>
            <p className="text-gray-700 text-lg mb-4 leading-relaxed">
              At the heart of Smiles Dental are our core values of compassion, integrity, and excellence.
              We are dedicated to treating each patient with the utmost respect and kindness, making their comfort our top priority.
            </p>
            <p className="text-gray-700 text-lg leading-relaxed">
              We hold ourselves to the highest standards in everything we do, from the treatments we provide to the relationships we build.
              Our team continually pursues excellence through ongoing education, state-of-the-art technology, and a deep commitment to ethical care.
              These values guide us as we strive to create a positive impact on our community, one smile at a time.
            </p>
          </div>
        </motion.div>
      </section>

      {/* Meet the Team Section */}
      <section className="py-16 px-8 md:px-16 bg-white">
        <motion.div
          className="max-w-4xl mx-auto flex flex-col items-center text-center"
          variants={sectionVariants}
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.5 }}
          transition={{ duration: 0.6, delay: 0.6 }}
        >
          <img src={teamImage} alt="Our Team" className="rounded-lg w-full h-64 object-cover mb-4" />
          <h2 className="text-3xl font-bold text-blue-800 mb-4">Meet Our Team</h2>
          <p className="text-gray-700 text-lg max-w-2xl leading-relaxed">
            Our team is made up of skilled, compassionate professionals who bring expertise, warmth, and dedication to every patient visit.
            Each member of our staff is committed to providing a welcoming environment where patients feel comfortable and valued.
            We believe that building lasting relationships with our patients starts with genuine care, open communication, and a friendly smile.
            Our dentists, hygienists, and administrative staff work together to make every visit a positive experience.
          </p>
          <p className="text-gray-700 text-lg max-w-2xl leading-relaxed mt-4">
            Our team members regularly attend continuing education courses to stay up-to-date with the latest advancements in dental care.
            This commitment to learning enables us to provide high-quality, effective treatment options, ensuring that every patient receives the best care possible.
          </p>
        </motion.div>
      </section>

      {/* Call to Action */}
      <section className="py-16 px-8 md:px-16 bg-blue-800 text-white text-center">
        <h2 className="text-3xl font-bold mb-4">We’re Here for You</h2>
        <p className="text-lg mb-6">Contact us today to schedule a visit or learn more about our team and services.</p>
        <a href="/healthcare/contact" className="bg-yellow-400 text-gray-900 px-6 py-3 rounded-lg font-medium hover:bg-yellow-500 transition">
          Contact Us
        </a>
      </section>
    </div>
  );
};

export default HealthcareAbout;
