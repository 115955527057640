import React from 'react';
import RealEstateNavBar from './RealEstateNavBar';
import heroVideo from '../../assets/realestate/re22com.mp4'; // Import your video file
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css'; 
import 'slick-carousel/slick/slick-theme.css';

// Import your images
import property1 from '../../assets/realestate/hows6.jpg';
import property2 from '../../assets/realestate/hows7.jpg';
import property3 from '../../assets/realestate/hows2.jpg';
import property4 from '../../assets/realestate/hows3.jpg';
import property5 from '../../assets/realestate/hows5.jpg';
import property6 from '../../assets/realestate/hows8.jpg';

const RealEstateHome = () => {
  const properties = [
    { name: "Modern Villa", image: property1 },
    { name: "Luxury Apartment", image: property2 },
    { name: "Beachfront House", image: property3 },
    { name: "Cozy Cottage", image: property4 },
    { name: "Stylish Loft", image: property5 },
    { name: "Charming Bungalow", image: property6 },
  ];

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="bg-ash-gray text-gray-900 font-montserrat"> {/* Set Montserrat as the base font */}
      <RealEstateNavBar />

      {/* Hero Section with Video */}
      <section className="relative h-screen overflow-hidden">
        <video 
          className="absolute inset-0 w-full h-full object-cover" 
          autoPlay 
          loop 
          muted
          src={heroVideo}
        />
        <div className="absolute inset-0 bg-black opacity-40"></div>

        {/* Circular Mask Effect */}
        <svg className="absolute bottom-0 right-0 w-1/2 h-3/4 z-20 opacity-75" viewBox="25 25 100 150">
          <defs>
            <clipPath id="circleMask">
              <circle cx="100" cy="100" r="100" fill="#A12B2F" /> {/* Deep Crimson */}
            </clipPath>
          </defs>
          <rect width="200" height="200" fill="#A12B2F" clipPath="url(#circleMask)" />
        </svg>

        {/* Hero Text Over Mask */}
        <div className="absolute bottom-0 right-0 w-1/2 h-3/4 flex flex-col items-center justify-center z-30 text-center">
  <h1 className="text-3xl sm:text-4xl md:text-5xl lg:text-6xl font-playfair mb-4 text-white px-4 sm:px-0 max-w-xs sm:max-w-md">
    Find Your Dream Home
  </h1>
  <p className="text-base sm:text-lg md:text-xl mb-8 text-white px-4 sm:px-0 max-w-xs sm:max-w-md">
    Discover exclusive properties tailored to your lifestyle.
  </p>
  <a
    href="/realestate/featured"
    className="bg-[#FF6700] text-white px-6 py-3 rounded-lg font-medium hover:bg-[#FF6700]/90 transition"
  >
    Explore Listings
  </a>
</div>
      </section>

      {/* Our Values Section */}
      <section className="py-16 px-8 md:px-16 bg-[#FDF0D5]"> {/* Creamy Beige */}
        <h2 className="text-3xl font-playfair mb-6 text-center text-[#C44536]">Our Values</h2> {/* Set Playfair Display for section headings */}
        <p className="text-gray-700 text-lg text-center max-w-3xl mx-auto mb-4">
          At NestQuest, we are dedicated to helping you find the perfect property. Our commitment to excellence ensures a seamless and rewarding experience every step of the way.
        </p>
        <p className="text-gray-700 text-lg text-center max-w-3xl mx-auto mb-4">
          We believe in transparency, integrity, and client satisfaction. Our team of experts is here to guide you in every aspect of your real estate journey.
        </p>
        <p className="text-gray-700 text-lg text-center max-w-3xl mx-auto mb-4">
          Join us as we strive to create lasting relationships and a community where everyone feels at home.
        </p>
      </section>

      {/* Featured Properties Carousel */}
      <section className="py-16 px-8 md:px-16 bg-gray-100">
        <h2 className="text-3xl font-playfair mb-6 text-center text-[#C44536]">Featured Properties</h2> {/* Set Playfair Display for section headings */}
        <p className="text-gray-600 text-lg text-center mb-8">Explore some of our most sought-after listings that define luxury and comfort.</p>
        
        <Slider {...settings}>
          {properties.map((property, index) => (
            <div key={index} className="bg-white rounded-lg shadow-lg p-6">
              <img src={property.image} alt={property.name} className="w-full h-48 object-cover mb-4 rounded-md" />
              <h3 className="text-xl font-semibold mb-2">{property.name}</h3>
              <p className="text-gray-600">Experience unmatched luxury and breathtaking views. Schedule a tour today.</p>
            </div>
          ))}
        </Slider>
      </section>

      {/* Contact CTA with Form */}
      <section className="py-16 px-8 md:px-16 bg-[#A12B2F] text-[#FDF0D5] text-center"> {/* Deep Crimson background */}
        <h2 className="text-3xl font-bold mb-4">Get in Touch</h2>
        <p className="text-lg mb-6">Contact us today for personalized assistance and expert advice.</p>
        
        <form className="max-w-md mx-auto space-y-4"> {/* Space between form elements */}
          <input 
            type="text" 
            placeholder="Your Name" 
            className="w-full p-3 rounded border border-gray-300" 
            required 
          />
          <input 
            type="email" 
            placeholder="Your Email" 
            className="w-full p-3 rounded border border-gray-300" 
            required 
          />
          <textarea 
            placeholder="Your Message" 
            className="w-full p-3 rounded border border-gray-300" 
            rows="4" 
            required 
          ></textarea>
          <button 
            type="submit" 
            className="bg-[#FF6700] text-white px-8 py-3 rounded-lg font-medium hover:bg-[#FF6700]/90 transition w-full"> {/* Adjusted for width */}
            Send Message
          </button>
        </form>
      </section>
    </div>
  );
};

export default RealEstateHome;
