import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const RealEstateNavBar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <nav className="bg-[#A12B2F] text-[#FDF0D5] shadow-lg"> {/* Deep Crimson background */}
      <div className="max-w-7xl mx-auto px-4 py-4 flex justify-between items-center">
        <Link to="/realestate" className="text-2xl font-bold font-playfair">NestQuest</Link> {/* Playfair Display for company name */}
        <div className="hidden md:flex space-x-6">
          <Link to="/realestate/about" className="hover:text-[#FF6700] transition font-montserrat">About Us</Link>
          <Link to="/realestate/featured" className="hover:text-[#FF6700] transition font-montserrat">Featured Properties</Link>
          <Link to="/realestate/testimonials" className="hover:text-[#FF6700] transition font-montserrat">Testimonials</Link>
          <Link to="/realestate/contact" className="hover:text-[#FF6700] transition font-montserrat">Contact</Link>
        </div>
        <div className="md:hidden">
          <button
            className="text-[#FDF0D5] focus:outline-none"
            onClick={toggleMenu}
          >
            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16m-7 6h7" />
            </svg>
          </button>
        </div>
      </div>
      {/* Mobile Menu */}
      {isOpen && (
        <div className="md:hidden px-4 pb-4 space-y-2">
          <Link to="/realestate/about" className="block hover:text-[#FF6700] transition font-montserrat">About Us</Link>
          <Link to="/realestate/featured" className="block hover:text-[#FF6700] transition font-montserrat">Featured Properties</Link>
          <Link to="/realestate/testimonials" className="block hover:text-[#FF6700] transition font-montserrat">Testimonials</Link>
          <Link to="/realestate/contact" className="block hover:text-[#FF6700] transition font-montserrat">Contact</Link>
        </div>
      )}
    </nav>
  );
};

export default RealEstateNavBar;
