import React from 'react';
import LogisticsNavBar from './LogisticsNavBar';
import heroImage from '../../assets/logistics/truck.jpg';
import truck1 from '../../assets/logistics/truck1.webp';
import truck2 from '../../assets/logistics/truck2.webp';
import truck3 from '../../assets/logistics/truck3.webp';
import truck4 from '../../assets/logistics/truck4.webp';

const LogisticsHome = () => {
  return (
    <div style={{ fontFamily: 'Ubuntu, sans-serif', backgroundColor: '#1a1a1a', color: '#e5e5e5' }}>
      <LogisticsNavBar/>
      
      {/* Hero Section */}
      <section className="relative bg-cover bg-center h-screen" style={{ backgroundImage: `url(${heroImage})` }}>
        <div className="absolute inset-0 bg-gray-800 opacity-40"></div>
        <div className="relative z-10 flex flex-col items-center justify-between h-full text-center text-orange-600 px-4 py-16">
        <h1 className="text-5xl sm:text-7xl md:text-8xl lg:text-9xl font-bold mt-6" style={{ fontFamily: 'Lobster, sans-serif' }}>
            TruckForce
          </h1>
          <div className="flex-grow"></div>
          <div className="mb-5">
            <h2 className="text-5xl font-bold mb-6 text-white">Delivering Efficiency, Every Mile</h2>
            <a href="/logistics/contact" className="bg-orange-600 text-gray-200 px-6 py-3 rounded-lg font-medium hover:bg-orange-700 transition">
              Get a Quote
            </a>
          </div>
        </div>
      </section>

      {/* Feature Sections */}
      <section className="py-16 px-8 md:px-16 bg-gray-900">
        <div className="flex flex-col md:flex-row items-center mb-16">
          <img src={truck1} alt="Refrigerated Transport" className="w-full md:w-1/2 rounded-lg shadow-lg mb-6 md:mb-0" />
          <div className="md:ml-8 text-center md:text-left">
            <h2 className="text-3xl font-bold text-orange-600">Refrigerated Transport</h2>
            <p className="text-gray-200 mt-4">
              With TruckForce’s advanced refrigerated transport solutions, your perishable goods are in safe hands. Our state-of-the-art temperature control ensures that sensitive products like food, pharmaceuticals, and other perishables stay within optimal conditions, preserving quality from pick-up to delivery. This robust, reliable system minimizes spoilage, so you can trust that your cargo arrives exactly as intended.
            </p>
          </div>
        </div>
        
        <div className="flex flex-col md:flex-row-reverse items-center mb-16">
          <img src={truck2} alt="24/7 Service" className="w-full md:w-1/2 rounded-lg shadow-lg mb-6 md:mb-0" />
          <div className="md:mr-8 text-center md:text-left">
            <h2 className="text-3xl font-bold text-orange-600">24/7 Service</h2>
            <p className="text-gray-200 mt-4">
              At TruckForce, our commitment to keeping your operations running smoothly means we’re available around the clock. Our 24/7 service provides real-time assistance whenever you need it, ensuring that urgent shipments, last-minute requests, and time-sensitive deliveries are handled efficiently. With a dedicated support team on standby, logistics challenges are met head-on, so you can focus on your business.
            </p>
          </div>
        </div>

        <div className="flex flex-col md:flex-row items-center mb-16">
          <img src={truck4} alt="Dedicated Fleet" className="w-full md:w-1/2 rounded-lg shadow-lg mb-6 md:mb-0" />
          <div className="md:ml-8 text-center md:text-left">
            <h2 className="text-3xl font-bold text-orange-600">Dedicated Fleet</h2>
            <p className="text-gray-200 mt-4">
              TruckForce’s dedicated fleet offers tailored transport solutions designed to integrate seamlessly with your supply chain. Our bespoke fleet services provide everything from specialized equipment to branded vehicles, allowing your business to maintain a consistent brand presence. By dedicating resources exclusively to your business, we ensure reliability, security, and a commitment to your unique logistics requirements.
            </p>
          </div>
        </div>

        <div className="flex flex-col md:flex-row-reverse items-center">
          <img src={truck3} alt="Real-Time Tracking" className="w-full md:w-1/2 rounded-lg shadow-lg mb-6 md:mb-0" />
          <div className="md:mr-8 text-center md:text-left">
            <h2 className="text-3xl font-bold text-orange-600">Real-Time Tracking</h2>
            <p className="text-gray-200 mt-4">
              Gain full control and transparency over your shipments with TruckForce’s real-time tracking. Our cutting-edge GPS tracking system allows you to monitor shipments at every stage, providing instant updates and accurate delivery times. This capability empowers you to make proactive decisions, optimize scheduling, and keep customers informed, transforming your logistics into a streamlined, client-focused operation.
            </p>
          </div>
        </div>
      </section>

      {/* Testimonials Section */}
      <section className="py-16 px-8 md:px-16 bg-gray-900">
        <h2 className="text-4xl font-bold text-center text-orange-600 mb-12">What Our Clients Say</h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          <div className="bg-gray-800 p-8 rounded-lg shadow-lg">
            <p className="text-gray-200 italic mb-6">
              "TruckForce has been an absolute game-changer for our supply chain management. Our previous logistics provider struggled to meet our demands, especially with temperature-sensitive goods, and we faced losses due to spoilage. Since switching to TruckForce, we haven’t had a single incident. Their real-time tracking gives us peace of mind, and their responsive customer service team handles issues promptly. We now have complete trust in our shipments arriving on time and in perfect condition, and this reliability has strengthened our own reputation with our customers."
            </p>
            <p className="font-bold text-orange-600 text-right">— Alex M., Chief Operating Officer</p>
          </div>

          <div className="bg-gray-800 p-8 rounded-lg shadow-lg">
            <p className="text-gray-200 italic mb-6">
              "Working with TruckForce has transformed our overnight and last-mile deliveries. We operate in a fast-paced industry where timing is everything, and TruckForce’s 24/7 availability has been crucial. They don’t just deliver goods; they deliver confidence. Their tracking system allows us to keep clients informed with precise updates, and their drivers are professional and well-prepared for any circumstance. TruckForce isn’t just a logistics provider for us; they are a critical partner in our operational success."
            </p>
            <p className="font-bold text-orange-600 text-right">— Sarah T., Director of Logistics</p>
          </div>

          <div className="bg-gray-800 p-8 rounded-lg shadow-lg">
            <p className="text-gray-200 italic mb-6">
              "We’ve been using TruckForce’s dedicated fleet services for over a year now, and the difference in service quality is clear. Their team truly understands our business needs and has crafted a logistics solution tailored to our requirements. The branding on their fleet has been a fantastic addition, reinforcing our brand’s presence on the road. Not only do they handle our logistical needs with utmost care, but their attention to detail and proactive approach also save us valuable time and resources. TruckForce delivers on every promise, and we couldn’t be more satisfied."
            </p>
            <p className="font-bold text-orange-600 text-right">— John R., CEO of Nationwide Retailers</p>
          </div>
        </div>
      </section>

      {/* Contact CTA */}
      <section className="py-16 px-8 md:px-16 bg-gray-800 text-gray-200 text-center">
        <h2 className="text-3xl font-bold mb-4">Need a Custom Logistics Solution?</h2>
        <p className="text-lg mb-6">Contact us today for a personalized logistics plan to meet your specific needs.</p>
        <a href="logistics/contact" className="bg-orange-600 text-gray-200 px-6 py-3 rounded-lg font-medium hover:bg-orange-700 transition">
          Contact Us
        </a>
      </section>
    </div>
  );
};

export default LogisticsHome;
