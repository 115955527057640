import React from 'react';
import RealEstateNavBar from './RealEstateNavBar';

// Import your images
import property1 from '../../assets/realestate/hows6.jpg';
import property2 from '../../assets/realestate/hows7.jpg';
import property3 from '../../assets/realestate/hows2.jpg';
import property4 from '../../assets/realestate/hows3.jpg';
import property5 from '../../assets/realestate/hows5.jpg';
import property6 from '../../assets/realestate/hows8.jpg';

const RealEstateFeatured = () => {
  const properties = [
    {
      name: "Modern Villa",
      image: property1,
      description: "This stunning modern villa offers breathtaking views and luxurious amenities, making it the perfect home for those who appreciate comfort and style.",
      features: ["4 Bedrooms", "3 Bathrooms", "2,500 sq ft", "Swimming Pool", "Ocean View"],
    },
    {
      name: "Luxury Apartment",
      image: property2,
      description: "Located in the heart of the city, this luxury apartment features spacious living areas and high-end finishes, ideal for urban living.",
      features: ["3 Bedrooms", "2 Bathrooms", "1,800 sq ft", "Gym Access", "Balcony"],
    },
    {
      name: "Beachfront House",
      image: property3,
      description: "Enjoy life by the beach in this charming beachfront house, with direct access to the sandy shores and stunning ocean views.",
      features: ["5 Bedrooms", "4 Bathrooms", "3,000 sq ft", "Private Dock", "Beach Access"],
    },
    {
      name: "Cozy Cottage",
      image: property4,
      description: "This cozy cottage provides a peaceful retreat, nestled in nature, perfect for those seeking tranquility and charm.",
      features: ["2 Bedrooms", "1 Bathroom", "1,200 sq ft", "Fireplace", "Garden"],
    },
    {
      name: "Stylish Loft",
      image: property5,
      description: "With an open-concept design, this stylish loft combines modern architecture with industrial touches, offering a unique living experience.",
      features: ["1 Bedroom", "1 Bathroom", "1,500 sq ft", "City Views", "Exposed Brick"],
    },
    {
      name: "Charming Bungalow",
      image: property6,
      description: "This charming bungalow is filled with character and warmth, featuring a spacious layout and a beautiful backyard, perfect for families.",
      features: ["3 Bedrooms", "2 Bathrooms", "1,800 sq ft", "Backyard", "Fire Pit"],
    },
  ];

  return (
    <div className="bg-[#FDF0D5] text-gray-900 font-montserrat"> {/* Creamy Beige background */}
      <RealEstateNavBar />
      
      <section className="py-16 px-8 md:px-16">
        <h2 className="text-4xl font-playfair mb-6 text-center text-[#A12B2F]">Featured Properties</h2> {/* Deep Crimson */}
        <p className="text-gray-700 text-lg text-center max-w-3xl mx-auto mb-8">
          Discover our curated selection of stunning properties that cater to your lifestyle and aspirations. Each home represents quality, luxury, and exceptional living.
        </p>
        
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {properties.map((property, index) => (
            <div key={index} className="bg-white rounded-lg shadow-lg overflow-hidden">
              <img src={property.image} alt={property.name} className="w-full h-48 object-cover mb-4" />
              <div className="p-6">
                <h3 className="text-xl font-semibold text-[#C44536] mb-2">{property.name}</h3> {/* Brick Red for titles */}
                <p className="text-gray-700 mb-4">{property.description}</p>
                <h4 className="font-bold mb-2">Features:</h4>
                <ul className="list-disc pl-5 text-gray-600">
                  {property.features.map((feature, i) => (
                    <li key={i}>{feature}</li>
                  ))}
                </ul>
                <div className="mt-4">
                  <button className="bg-[#A12B2F] text-[#FDF0D5] px-6 py-2 rounded-lg font-medium hover:bg-[#C44536] transition"> {/* Deep Crimson for button */}
                    Schedule a Tour
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      </section>
    </div>
  );
};

export default RealEstateFeatured;
