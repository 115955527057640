// src/pages/Education/ArticlesPage.js

import React from "react";
import articleImage1 from "../../assets/education/a1.webp";
import articleImage2 from "../../assets/education/a2.webp";
import articleImage3 from "../../assets/education/a3.webp";
import articleImage4 from "../../assets/education/a4.webp";
import articleImage5 from "../../assets/education/a5.webp";
import articleImage6 from "../../assets/education/a6.webp";

import EducationNavBar from "./EducationNavBar";



const articles = [
  {
    title: "Understanding Modern AI",
    author: "Jane Doe",
    date: "October 25, 2024",
    image: articleImage1,
    excerpt: "Artificial Intelligence has moved from science fiction to a powerful tool in almost every industry. This article delves into the latest advancements in machine learning, deep learning, and AI ethics. Discover the applications transforming finance, healthcare, and even creative arts.",
  },
  {
    title: "Tech Trends in Education",
    author: "John Smith",
    date: "October 20, 2024",
    image: articleImage2,
    excerpt: "As education systems adapt to the digital era, technology plays a pivotal role in enhancing accessibility and engagement. Learn how AI, AR, and VR are reshaping classrooms, making learning interactive and data-driven, and opening up new opportunities for students worldwide.",
  },
  {
    title: "Career Advice for Graduates",
    author: "Emily Johnson",
    date: "October 15, 2024",
    image: articleImage3,
    excerpt: "Entering the workforce can be daunting for new graduates. From networking to developing crucial skills, we provide a roadmap for early career success, and insights into navigating challenges and maximizing growth in the evolving job market.",
  },
  {
    title: "The Future of Blockchain",
    author: "Alex Lee",
    date: "October 10, 2024",
    image: articleImage4,
    excerpt: "Blockchain technology goes far beyond cryptocurrencies. Explore its revolutionary potential in various industries, from supply chain management to secure voting systems, and understand how it could redefine trust and transparency globally.",
  },
  {
    title: "Data Privacy in the Digital Age",
    author: "Rachel Adams",
    date: "October 5, 2024",
    image: articleImage5,
    excerpt: "With increasing data collection, privacy has become a major concern. This article examines the laws and technologies shaping data privacy, offering advice on safeguarding personal information in an interconnected world.",
  },
  {
    title: "Mastering Remote Work",
    author: "Chris Evans",
    date: "October 1, 2024",
    image: articleImage6,
    excerpt: "Remote work has become a standard, not a trend. We discuss the tools and habits that help professionals stay productive and connected, along with insights into how remote work culture is reshaping organizational structures.",
  },
];

const EducationArticles = () => {
  return (
    <div className="bg-gray-800 text-slate-200 font-play ">
        <EducationNavBar/>
      <h1 className="text-4xl font-merriweather text-center mb-4 py-12 text-amber-500">Recent Articles</h1>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
        {articles.map((article, index) => (
          <div key={index} className="bg-slate-900 border border-gray-700 rounded-lg shadow-lg p-6">
            <img
              src={article.image}
              alt={article.title}
              className="w-full h-48 object-cover rounded mb-4"
            />
            <h2 className="text-2xl font-merriweather mb-2 text-amber-500">{article.title}</h2>
            <p className="text-sm text-gray-400 mb-4">
              By {article.author} | {article.date}
            </p>
            <p className="text-gray-300 mb-4">{article.excerpt}</p>
            <a href="/education/sample" className="bg-amber-500 text-slate-900 px-4 py-2 rounded font-lato font-medium hover:bg-amber-600 transition">
              Read More
            </a>
          </div>
        ))}
      </div>
    </div>
  );
};

export default EducationArticles;
