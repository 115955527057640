import React from "react";
import LogisticsNavBar from "./LogisticsNavBar";
import heroImage from '../../assets/logistics/truck.jpg';
import teamImage from '../../assets/logistics/team.webp';


const LogisticsAbout = () => {
  return (
    <div className="bg-gray-900 text-gray-200 font-ubuntu">
      <LogisticsNavBar />
      {/* Header */}
      <header className="bg-gray-800 text-center py-10">
        <h1 className="text-5xl font-bold text-orange-600" style={{ fontFamily: 'Lobster, sans-serif' }}>About TruckForce</h1>
        <p className="mt-4 text-gray-200 max-w-2xl mx-auto">
          At TruckForce, we are more than just a logistics company. We are a team of passionate professionals dedicated to redefining supply chain solutions with precision, reliability, and forward-thinking innovations.
        </p>
      </header>

      {/* Our Mission */}
      <section className="py-16 px-8 md:px-16 bg-gray-900">
        <div className="flex flex-col md:flex-row items-center mb-12">
          <div className="md:w-1/2 mb-6 md:mb-0">
            <h2 className="text-3xl font-bold text-orange-600 mb-4" style={{ fontFamily: 'Lobster, sans-serif' }}>Our Mission</h2>
            <p className="text-gray-200">
              Our mission is simple yet profound: to be the most reliable logistics partner for businesses worldwide. We aim to provide innovative, efficient, and customized logistics solutions that help our clients thrive in today’s competitive marketplace. With a steadfast commitment to excellence, we empower our clients by taking the complexity out of logistics, allowing them to focus on what they do best — growing their business.
            </p>
          </div>
          <img src={heroImage} alt="Our Mission" className="md:w-1/2 rounded-lg shadow-lg pl-6" />
        </div>
      </section>

      {/* Our Story */}
      <section className="py-16 px-8 md:px-16 bg-gray-900">
        <h2 className="text-4xl font-bold text-center text-orange-600 mb-12" style={{ fontFamily: 'Lobster, sans-serif' }}>Our Story</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-12">
          <p className="text-gray-200">
            TruckForce was founded with a vision to bridge the gap in dependable logistics services for businesses. From humble beginnings, we have grown into a trusted name in the logistics industry, serving clients across diverse sectors. What started as a small operation has evolved into a comprehensive logistics network powered by cutting-edge technology and a team that believes in the core values of transparency, integrity, and dedication.
          </p>
          <p className="text-gray-200">
            Over the years, TruckForce has consistently pushed boundaries, innovating to provide services that meet the ever-changing needs of the market. With a focus on sustainable practices and continuous improvement, we’ve implemented systems and processes that not only benefit our clients but also contribute to reducing our environmental footprint. Our story is one of resilience, growth, and an unyielding commitment to our mission.
          </p>
        </div>
      </section>

      {/* Our Values */}
      <section className="py-16 px-8 md:px-16 bg-gray-900">
        <h2 className="text-4xl font-bold text-center text-orange-600 mb-12" style={{ fontFamily: 'Lobster, sans-serif' }}>Our Values</h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          <div className="bg-gray-800 p-8 rounded-lg shadow-lg">
            <h3 className="text-2xl font-bold text-orange-600 mb-4">Integrity</h3>
            <p className="text-gray-200">
              Integrity is the cornerstone of everything we do. From transparent pricing to honest communication, we build trust with our clients through actions that reflect our commitment to doing business the right way.
            </p>
          </div>

          <div className="bg-gray-800 p-8 rounded-lg shadow-lg">
            <h3 className="text-2xl font-bold text-orange-600 mb-4">Reliability</h3>
            <p className="text-gray-200">
              Our clients depend on us to handle their logistics challenges with precision and reliability. We have developed processes that ensure every shipment is handled with care, punctuality, and accountability, regardless of its complexity.
            </p>
          </div>

          <div className="bg-gray-800 p-8 rounded-lg shadow-lg">
            <h3 className="text-2xl font-bold text-orange-600 mb-4">Innovation</h3>
            <p className="text-gray-200">
              At TruckForce, innovation drives us forward. We constantly seek new ways to improve our services, incorporating the latest technologies and sustainable practices to stay ahead in the logistics industry.
            </p>
          </div>
        </div>
      </section>

      {/* Our Team */}
      <section className="py-16 px-8 md:px-16 bg-gray-900">
        <div className="flex flex-col md:flex-row items-center mb-12">
          <img src={teamImage} alt="Our Team" className="md:w-1/2 rounded-lg shadow-lg mb-6 md:mb-0" />
          <div className="md:w-1/2 md:ml-8">
            <h2 className="text-3xl font-bold text-orange-600 mb-4" style={{ fontFamily: 'Lobster, sans-serif' }}>Our Team</h2>
            <p className="text-gray-200">
              Behind every successful delivery is a team of dedicated professionals who are passionate about logistics. Our team consists of industry experts, drivers, logistics coordinators, customer support staff, and technology specialists — all working in sync to deliver outstanding results. 
            </p>
            <p className="text-gray-200 mt-4">
              Every member of TruckForce shares a common goal: to exceed client expectations. From the planning stages to execution and follow-up, our team is trained to handle the most challenging logistics scenarios, providing a seamless experience for our clients.
            </p>
          </div>
        </div>
      </section>

     
    </div>
  );
};

export default LogisticsAbout;
