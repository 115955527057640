import React from 'react';
import ECommerceNavBar from './ECommerceNavBar';
import heroImage from '../../assets/ecommerce/logo3.webp';

const ECommerceAbout = () => {
  return (
    <div className="bg-slate-900 text-white px-6 font-oswald">
      <ECommerceNavBar />
      
      {/* Header */}
      <header className="text-center mb-16">
        <h1 className="text-5xl font-extrabold text-yellow-500 py-10 font-anton">About Graf Streetwear</h1>
        <p className="text-gray-300 mt-4 max-w-2xl mx-auto">
          At Graf Streetwear, we believe in more than just clothing – we believe in expression, in attitude, and in the art of standing out. Here’s what drives us.
        </p>
      </header>

      {/* Brand Story */}
      <section className="container mx-auto grid grid-cols-1 md:grid-cols-2 gap-12">
        <div>
          <h2 className="text-3xl font-bold text-pink-600 mb-4 font-anton">Our Story</h2>
          <p className="text-gray-300 leading-relaxed mb-6">
            Born from the heart of urban culture, Graf Streetwear started as a movement among friends who were tired of the status quo. We wanted clothing that captured the energy of the streets – bold, fearless, and unapologetically original. Our journey began in a small studio where every piece was crafted by hand, inspired by graffiti art, music, and the pulse of the city.
          </p>
          <p className="text-gray-300 leading-relaxed mb-6">
            Fast forward to today, Graf Streetwear has become a symbol of individuality and rebellion. Our designs are more than just apparel – they’re a canvas for self-expression, an extension of the culture we live and breathe. Whether you’re hitting the city streets or making your own rules, Graf Streetwear is here to keep you geared up for the journey.
          </p>
        </div>

        {/* Right Column: Image */}
        <div className="relative h-96 md:h-auto">
          <img src={heroImage} alt="Graf Story" className="w-full h-full object-cover rounded-lg shadow-lg opacity-90" />
        </div>
      </section>

      {/* Values and Mission Section */}
      <section className="container mx-auto mt-16 grid grid-cols-1 md:grid-cols-3 gap-12 text-center">
        <div className="bg-gray-800 rounded-lg p-8 shadow-lg">
          <h3 className="text-xl font-bold text-yellow-500 mb-4 font-anton">Our Mission</h3>
          <p className="text-gray-300 leading-relaxed">
            To empower individuality and redefine streetwear by creating pieces that let you make a statement without saying a word.
          </p>
        </div>
        <div className="bg-gray-800 rounded-lg p-8 shadow-lg">
          <h3 className="text-xl font-bold text-blue-700 mb-4 font-anton">Our Vision</h3>
          <p className="text-gray-300 leading-relaxed">
            We envision a world where style meets authenticity, where fashion reflects the raw, unfiltered energy of urban culture.
          </p>
        </div>
        <div className="bg-gray-800 rounded-lg p-8 shadow-lg">
          <h3 className="text-xl font-bold text-pink-600 mb-4 font-anton">Our Values</h3>
          <p className="text-gray-300 leading-relaxed">
            Creativity. Rebellion. Inclusivity. These are the core values that shape every stitch, every design, and every detail at Graf Streetwear.
          </p>
        </div>
      </section>

      {/* Founder Message Section */}
      <section className="container mx-auto mt-16 text-center px-6 md:px-20">
        <h2 className="text-3xl font-bold text-yellow-500 mb-8 font-anton">A Message from Our Founder</h2>
        <p className="text-gray-300 leading-relaxed max-w-3xl mx-auto">
          "Graf Streetwear was born from a passion for culture and an obsession with quality. When I started this journey, my goal was to bring something real to the streetwear scene – something that wasn’t afraid to break the mold. Every piece we design has a story, an inspiration drawn from the raw grit of the streets, the vivid colors of graffiti, and the rhythm of the city. Thank you for being part of this journey with us. We’re just getting started."
        </p>
        <p className="text-gray-400 mt-4 font-oswald">- Founder, Graf Streetwear</p>
      </section>
    </div>
  );
};

export default ECommerceAbout;
