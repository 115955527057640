// src/components/Card.js

import { Link } from "react-router-dom";

const Card = ({ title, link, image, description }) => (
  <div className="bg-gray-800 rounded-lg shadow-lg transform transition-transform duration-300 hover:scale-105 hover:shadow-xl overflow-hidden border border-gray-700">
    {/* Card Image */}
    <img src={image} alt={title} className="w-full h-48 object-cover" />

    {/* Card Content Box */}
    <div className="flex flex-col justify-between p-6 h-80 bg-gray-900 text-center">
      <div>
        {/* Title with expanded height */}
        <h3 className="text-2xl font-semibold mb-2 text-purple-400 h-20 overflow-hidden">
          {title}
        </h3>
        {/* Description with expanded height */}
        <p className="text-gray-300 mb-6 h-20 overflow-hidden">
          {description}
        </p>
      </div>
      <Link 
        to={link} 
        className="inline-block bg-blue-600 text-white font-medium py-2 px-4 rounded hover:bg-blue-700 transition-colors duration-200"
      >
        Explore
      </Link>
    </div>
  </div>
);

export default Card;
