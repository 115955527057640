import React from 'react';
import FurnitureNavBar from './FurnitureNavBar';

const FurnitureAbout = () => {
  return (
    <div className="bg-gray-100 text-gray-800 font-sans" style={{ fontFamily: 'Cormorant Garamond, serif' }}>
        <FurnitureNavBar/>
      {/* Hero Section */}
      <section className="bg-gray-800 text-gray-100 p-12 text-center">
        <h1 className="text-4xl font-bold text-yellow-400 mb-4">About Us</h1>
        <p className="text-lg max-w-3xl mx-auto">
          At TimberWorks, we believe that furniture is more than just functional. It’s a form of expression, a testament to craftsmanship, and a piece of art that becomes a part of your life’s story.
        </p>
      </section>

      {/* Our Story Section */}
      <section className="py-16 px-8 md:px-16 bg-gray-100">
        <h2 className="text-3xl font-bold text-center mb-6 text-gray-800">Our Story</h2>
        <p className="text-lg text-gray-800 text-center max-w-3xl mx-auto mb-12">
          Founded on a passion for woodworking and a commitment to quality, TimberWorks was born from the desire to create handcrafted pieces that last for generations. With each project, we aim to blend traditional woodworking techniques with modern aesthetics, producing timeless furniture that honors both form and function.
        </p>
        
        <div className="grid md:grid-cols-2 gap-12">
          {/* About Our Founder */}
          <div className="bg-white rounded-lg shadow-lg p-8 text-center">
            <h3 className="text-2xl font-semibold text-yellow-600">Our Founder</h3>
            <p className="text-gray-700 mt-4">
              With over 20 years of experience, our founder brings a deep respect for the craft and an unparalleled attention to detail. His journey began as an apprentice in a small workshop, where he learned the fundamentals of woodworking and developed a love for sustainable practices.
            </p>
            <p className="text-gray-700 mt-4">
              Today, he leads TimberWorks with the same values he learned in those early years: patience, precision, and a commitment to excellence.
            </p>
          </div>

          {/* Our Philosophy */}
          <div className="bg-white rounded-lg shadow-lg p-8 text-center">
            <h3 className="text-2xl font-semibold text-yellow-600">Our Philosophy</h3>
            <p className="text-gray-700 mt-4">
              At TimberWorks, we believe in working with nature, not against it. Every piece of wood we use is hand-selected for its unique grain, color, and character. Our goal is to highlight the natural beauty of the wood, crafting each piece to enhance its inherent qualities rather than mask them.
            </p>
            <p className="text-gray-700 mt-4">
              We also prioritize sustainability in everything we do, ensuring that each piece is crafted responsibly and with respect for our environment.
            </p>
          </div>
        </div>
      </section>

      {/* Why Choose Us Section */}
      <section className="py-16 px-8 md:px-16 bg-gray-200">
        <h2 className="text-3xl font-bold text-center text-gray-800 mb-6">Why Choose TimberWorks?</h2>
        <p className="text-lg text-center max-w-3xl mx-auto text-gray-800 mb-12">
          Choosing TimberWorks means choosing quality, sustainability, and a piece of art that’s as unique as you are. Here’s why clients continue to choose us for their custom woodwork needs:
        </p>
        
        <div className="grid md:grid-cols-3 gap-12">
          {/* Quality Craftsmanship */}
          <div className="bg-white rounded-lg shadow-lg p-8 text-center">
            <h3 className="text-2xl font-semibold text-yellow-600">Quality Craftsmanship</h3>
            <p className="text-gray-700 mt-4">
              Our pieces are designed to endure. Each item is meticulously crafted to ensure strength, beauty, and longevity. From sturdy tables to intricate cabinetry, our work speaks of dedication and expertise.
            </p>
          </div>

          {/* Sustainable Practices */}
          <div className="bg-white rounded-lg shadow-lg p-8 text-center">
            <h3 className="text-2xl font-semibold text-yellow-600">Sustainable Practices</h3>
            <p className="text-gray-700 mt-4">
              TimberWorks is committed to environmental stewardship. We source our wood responsibly, minimize waste, and strive to reduce our ecological footprint. We believe in creating furniture that’s as kind to the planet as it is beautiful.
            </p>
          </div>

          {/* Customization & Personalization */}
          <div className="bg-white rounded-lg shadow-lg p-8 text-center">
            <h3 className="text-2xl font-semibold text-yellow-600">Customization & Personalization</h3>
            <p className="text-gray-700 mt-4">
              Your furniture should reflect your style and needs. That’s why we offer complete customization, working closely with you to design pieces that are not only functional but also deeply personal.
            </p>
          </div>
        </div>
      </section>

      {/* Meet the Team */}
      <section className="py-16 px-8 md:px-16 bg-gray-100">
        <h2 className="text-3xl font-bold text-center mb-6 text-gray-800">Meet Our Team</h2>
        <p className="text-lg text-gray-800 text-center max-w-3xl mx-auto mb-12">
          Our team of skilled artisans, designers, and woodworkers bring a wealth of knowledge and experience to every project. With backgrounds in both traditional woodworking and contemporary design, we are united by a shared passion for craftsmanship and creativity.
        </p>

        <div className="grid md:grid-cols-3 gap-12">
          {/* Team Member 1 */}
          <div className="bg-white rounded-lg shadow-lg p-8 text-center">
            <h3 className="text-xl font-semibold text-yellow-600">Samuel Greene</h3>
            <p className="text-gray-700 mt-2">Master Woodworker</p>
            <p className="text-gray-700 mt-4">With a keen eye for detail, Samuel has been crafting unique pieces for over 15 years, specializing in intricate wood joinery and sustainable practices.</p>
          </div>

          {/* Team Member 2 */}
          <div className="bg-white rounded-lg shadow-lg p-8 text-center">
            <h3 className="text-xl font-semibold text-yellow-600">Lily Tran</h3>
            <p className="text-gray-700 mt-2">Lead Designer</p>
            <p className="text-gray-700 mt-4">Lily's designs balance elegance with functionality, combining modern aesthetics with the timeless appeal of natural wood.</p>
          </div>

          {/* Team Member 3 */}
          <div className="bg-white rounded-lg shadow-lg p-8 text-center">
            <h3 className="text-xl font-semibold text-yellow-600">Aiden Foster</h3>
            <p className="text-gray-700 mt-2">Finishing Specialist</p>
            <p className="text-gray-700 mt-4">Aiden ensures each piece is polished to perfection, bringing out the beauty of the wood grain with finishes that are both durable and eco-friendly.</p>
          </div>
        </div>
      </section>

      {/* Contact Call-to-Action */}
      <section className="py-16 px-8 md:px-16 bg-gray-800 text-white text-center">
        <h2 className="text-3xl font-bold mb-4" style={{ fontFamily: 'Cormorant Garamond, serif' }}>
          Learn More About Our Work
        </h2>
        <p className="text-lg mb-6" style={{ fontFamily: 'Cormorant Garamond, serif', fontWeight: 300 }}>
          TimberWorks is here to answer your questions and discuss how we can help make your vision a reality. Contact us today to learn more about our process and dedication to the craft.
        </p>
        <a href="/furniture/contact" className="bg-white text-gray-800 px-6 py-3 rounded-lg font-medium hover:bg-gray-100 transition" style={{ fontFamily: 'Cormorant Garamond, serif' }}>
          Get In Touch
        </a>
      </section>
    </div>
  );
};

export default FurnitureAbout;
