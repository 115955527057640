import React from "react";
import FoodNavBar from "./FoodNavBar";
import tileBackgroundImage from '../../assets/food/tile.webp';
import tileBackgroundImage1 from '../../assets/food/bbc3.webp';

const FoodEvents = () => {
  return (
    <div className="min-h-screen bg-[#F5F1ED] text-gray-900 font-sans">
      <FoodNavBar />
      
      <header 
        className="relative bg-[#502419] text-white p-6 text-center border-4 border-[#D72638] mb-8"
        style={{ backgroundImage: `url(${tileBackgroundImage})`, backgroundSize: 'cover' }}
      >
        <h1 className="text-4xl sm:text-5xl font-bold leading-tight sm:leading-snug mb-2" style={{ fontFamily: 'Pacifico, cursive' }}>
          Upcoming Events at Bubbly Brew Co.
        </h1>
       
      </header>

      <main className="p-6 max-w-screen-lg mx-auto" style={{ backgroundImage: `url(${tileBackgroundImage1})`, backgroundSize: 'cover' }}>
        
        {/* Event 1 */}
        <section className="my-8">
          <h2 className="text-3xl sm:text-4xl font-semibold mb-4 text-center sm:text-left" style={{ fontFamily: 'Pacifico, cursive' }}>
            <span className="bg-[#F6C90E] inline-block p-2 rounded-lg max-w-full sm:max-w-none">Brewery Tour & Tasting</span>
          </h2>
          <div className="bg-[#D3D3D3] text-[#502419] p-6 rounded-lg shadow-lg border-4 border-[#1687A7] relative">
            <div className="absolute inset-0 border-8 border-ridge border-[#D72638] rounded-lg -m-2"></div>
            <p className="text-lg mb-4">
              <strong>Date:</strong> March 15, 2024
            </p>
            <p className="text-lg mb-4">
              Join us for an exciting behind-the-scenes tour of our brewery! Get an exclusive look at 
              our brewing process, meet our passionate team, and learn about the ingredients that go into 
              your favorite brews. The tour will be followed by a tasting session featuring a selection of 
              our craft beers. It's a perfect opportunity to discover the art of brewing and enjoy great 
              company. Tickets are limited, so reserve your spot today!
            </p>
          </div>
        </section>

        {/* Event 2 */}
        <section className="my-8">
          <h2 className="text-3xl sm:text-4xl font-semibold mb-4 text-center sm:text-left" style={{ fontFamily: 'Pacifico, cursive' }}>
            <span className="bg-[#F6C90E] inline-block p-2 rounded-lg max-w-full sm:max-w-none">Craft Beer Festival</span>
          </h2>
          <div className="bg-[#D3D3D3] text-[#502419] p-6 rounded-lg shadow-lg border-4 border-[#1687A7] relative">
            <div className="absolute inset-0 border-8 border-ridge border-[#D72638] rounded-lg -m-2"></div>
            <p className="text-lg mb-4">
              <strong>Date:</strong> April 20, 2024
            </p>
            <p className="text-lg mb-4">
              Celebrate the best of craft beer at our annual Craft Beer Festival! Enjoy a wide variety 
              of local brews, food trucks, live music, and fun activities for all ages. This event is 
              perfect for beer lovers and families alike. Don’t miss out on the chance to sample exclusive 
              brews from Bubbly Brew Co. and other local breweries. Grab your friends and join us for a 
              day filled with great beer and good vibes!
            </p>
          </div>
        </section>

        {/* Event 3 */}
        <section className="my-8">
          <h2 className="text-3xl sm:text-4xl font-semibold mb-4 text-center sm:text-left" style={{ fontFamily: 'Pacifico, cursive' }}>
            <span className="bg-[#F6C90E] inline-block p-2 rounded-lg max-w-full sm:max-w-none">Beer & Food Pairing Night</span>
          </h2>
          <div className="bg-[#D3D3D3] text-[#502419] p-6 rounded-lg shadow-lg border-4 border-[#1687A7] relative">
            <div className="absolute inset-0 border-8 border-ridge border-[#D72638] rounded-lg -m-2"></div>
            <p className="text-lg mb-4">
              <strong>Date:</strong> May 10, 2024
            </p>
            <p className="text-lg mb-4">
              Join us for an unforgettable evening of beer and food pairing! Our head brewer and chef 
              will guide you through a delightful five-course meal, expertly paired with our craft beers. 
              Learn about flavor profiles and how to complement your favorite brews with delicious dishes. 
              This is an interactive event where you can ask questions and discover new pairings. Reserve 
              your seat for a night of culinary exploration!
            </p>
          </div>
        </section>

        {/* Event 4 */}
        <section className="my-8">
          <h2 className="text-3xl sm:text-4xl font-semibold mb-4 text-center sm:text-left" style={{ fontFamily: 'Pacifico, cursive' }}>
            <span className="bg-[#F6C90E] inline-block p-2 rounded-lg max-w-full sm:max-w-none">Live Music & Brews</span>
          </h2>
          <div className="bg-[#D3D3D3] text-[#502419] p-6 rounded-lg shadow-lg border-4 border-[#1687A7] relative">
            <div className="absolute inset-0 border-8 border-ridge border-[#D72638] rounded-lg -m-2"></div>
            <p className="text-lg mb-4">
              <strong>Date:</strong> June 5, 2024
            </p>
            <p className="text-lg mb-4">
              Join us for a night of live music featuring local bands and artists! Enjoy your favorite 
              craft beers while soaking up the sounds of talented musicians in a relaxed and friendly 
              atmosphere. Bring your friends, unwind, and let the good times roll. This is a perfect 
              way to kick off the summer season. Mark your calendars and don’t miss out on this great 
              evening of fun and entertainment!
            </p>
          </div>
        </section>
      </main>
    </div>
  );
};

export default FoodEvents;
