import React from "react";
import FinanceNavBar from "./FinanceNavBar"; // Assuming you have a nav bar component
import featureImage from "../../assets/finance/mc-1.webp"; // Importing the image
import featureImage1 from "../../assets/finance/mc-2.jpg"; // Importing the image
import featureImage2 from "../../assets/finance/mc-3.jpg"; // Importing the image
import featureImage3 from "../../assets/finance/mc-4.jpg"; // Importing the image
import featureImage4 from "../../assets/finance/mc-5.webp"; // Importing the image
import featureImage5 from "../../assets/finance/mc-6.jpg"; // Importing the image


const FinanceFeatures = () => {
  return (
    <div className="min-h-screen bg-black text-white font-play">
      <FinanceNavBar />

      {/* Page Heading */}
      <div className="text-center my-8">
        <h1 className="text-6xl font-extrabold text-[#FFD700] mb-4">Key Features of PaySphere</h1>
        <p className="text-lg mb-8">Discover how PaySphere can revolutionize your payment experience.</p>
      </div>

      {/* Features List */}
      <main className="p-6 max-w-screen-lg mx-auto grid grid-cols-1 md:grid-cols-2 gap-8">
        {/* Feature 1 */}
        <section className="relative border-4 border-purple-600 rounded-lg bg-gray-800 p-6 flex flex-col items-center">
          <img
            src={featureImage}
            alt="Secure Payments"
            className="w-full h-40 object-cover rounded-t-lg mb-4"
          />
          <h2 className="text-4xl font-semibold mb-4">Secure Payments</h2>
          <p className="text-lg">
            Your transactions are protected with advanced security measures, ensuring peace of mind for every payment.
          </p>
        </section>

        {/* Feature 2 */}
        <section className="relative border-4 border-purple-600 rounded-lg bg-gray-800 p-6 flex flex-col items-center">
          <img
            src={featureImage1}
            alt="Multi-Currency Support"
            className="w-full h-40 object-cover rounded-t-lg mb-4"
          />
          <h2 className="text-4xl font-semibold mb-4">Multi-Currency Support</h2>
          <p className="text-lg">
            Conduct transactions in various currencies, allowing businesses to reach a global audience effortlessly.
          </p>
        </section>

        {/* Feature 3 */}
        <section className="relative border-4 border-purple-600 rounded-lg bg-gray-800 p-6 flex flex-col items-center">
          <img
            src={featureImage2}
            alt="User-Friendly Dashboard"
            className="w-full h-40 object-cover rounded-t-lg mb-4"
          />
          <h2 className="text-4xl font-semibold mb-4">User-Friendly Dashboard</h2>
          <p className="text-lg">
            With our user-friendly dashboard, you can manage payments, view analytics, and generate reports with ease.
          </p>
        </section>

        {/* Feature 4 */}
        <section className="relative border-4 border-purple-600 rounded-lg bg-gray-800 p-6 flex flex-col items-center">
          <img
            src={featureImage3}
            alt="Fraud Detection"
            className="w-full h-40 object-cover rounded-t-lg mb-4"
          />
          <h2 className="text-4xl font-semibold mb-4">Fraud Detection</h2>
          <p className="text-lg">
            We implement robust fraud detection algorithms to ensure safe transactions, protecting both you and your customers.
          </p>
        </section>

        {/* Feature 5 */}
        <section className="relative border-4 border-purple-600 rounded-lg bg-gray-800 p-6 flex flex-col items-center">
          <img
            src={featureImage4}
            alt="Customer Support"
            className="w-full h-40 object-cover rounded-t-lg mb-4"
          />
          <h2 className="text-4xl font-semibold mb-4">Customer Support</h2>
          <p className="text-lg">
            Our team is available 24/7 to assist you with any inquiries, ensuring you have the support you need when you need it.
          </p>
        </section>

        {/* Feature 6 */}
        <section className="relative border-4 border-purple-600 rounded-lg bg-gray-800 p-6 flex flex-col items-center">
          <img
            src={featureImage5}
            alt="Recurring Billing"
            className="w-full h-40 object-cover rounded-t-lg mb-4"
          />
          <h2 className="text-4xl font-semibold mb-4">Recurring Billing</h2>
          <p className="text-lg">
            Automate billing for your subscription services effortlessly, reducing the time spent on manual processes.
          </p>
        </section>
      </main>
    </div>
  );
};

export default FinanceFeatures;
