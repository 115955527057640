import React from "react";
import FinanceNavBar from "./FinanceNavBar"; // Navbar component
import RotatingSphere from "../../components/Sphere"; // Your rotating sphere component

const FinanceHome = () => {
  return (
    <div className="min-h-screen bg-black text-white font-play">
      {/* Navbar with zIndex */}
      <div style={{ position: 'relative', zIndex: 10 }}>
        <FinanceNavBar />
      </div>

      <RotatingSphere />

      {/* Main Heading */}
      <div
        className="absolute inset-0 flex flex-col items-center justify-center text-center"
        style={{
          zIndex: 5, // Ensure heading is above the sphere
        }}
      >
        <h1 className="text-6xl font-extrabold text-[#FFD700] mb-4">PaySphere</h1>
        <p className="text-lg mb-8">Your trusted partner for secure and efficient payments.</p>
        <button className="bg-gradient-to-r from-purple-600 to-purple-400 text-white px-8 py-3 rounded-full font-bold hover:opacity-90 transition transform hover:-translate-y-1">
          Get Started
        </button>
      </div>

      {/* Big Gap Before About Section */}
      <div className="pt-110" /> {/* Adjust padding as needed */}

      {/* About Section */}
      <main className="p-6 max-w-screen-lg mx-auto bg-opacity-80">
        <section className="my-8">
          <h2 className="text-4xl font-semibold mb-4">What We Do</h2>
          <div className="bg-gray-800 bg-opacity-80 shadow-lg rounded-lg p-6 mb-4 border-4 border-purple-500">
            <p className="text-lg mb-4">
              At PaySphere, we facilitate secure online and mobile transactions, ensuring your financial safety and peace of mind. 
              Our platform supports multiple currencies, allowing businesses to reach a global audience effortlessly.
            </p>
            <p className="text-lg mb-4">
              With our user-friendly dashboard, you can manage payments, view analytics, and generate reports with ease. 
              We offer seamless integration options for e-commerce platforms, accounting software, and mobile apps.
            </p>
          </div>
        </section>

        {/* Bento Box Section */}
        <section className="my-8">
          <h2 className="text-4xl font-semibold mb-4">Explore PaySphere</h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6">
            <div className="bg-gray-700 p-4 rounded-lg border-4 border-purple-500 shadow-lg">
              <h3 className="text-xl font-bold mb-2">Secure Transactions</h3>
              <p className="text-sm">
                Rely on top-tier encryption and multi-layered fraud detection, ensuring every transaction is safe.
              </p>
            </div>
            <div className="bg-gray-700 p-4 rounded-lg border-4 border-purple-500 shadow-lg">
              <h3 className="text-xl font-bold mb-2">Global Currency Options</h3>
              <p className="text-sm">
                Expand your reach by accepting multiple currencies, making international business seamless.
              </p>
            </div>
            <div className="bg-gray-700 p-4 rounded-lg border-4 border-purple-500 shadow-lg">
              <h3 className="text-xl font-bold mb-2">24/7 Support</h3>
              <p className="text-sm">
                Our support team is ready at all hours to assist, ensuring smooth transactions every time.
              </p>
            </div>
            <div className="bg-gray-700 p-4 rounded-lg border-4 border-purple-500 shadow-lg sm:col-span-2 md:col-span-1">
              <h3 className="text-xl font-bold mb-2">In-Depth Analytics</h3>
              <p className="text-sm">
                Access powerful insights with detailed analytics, helping you make data-driven decisions.
              </p>
            </div>
            <div className="bg-gray-700 p-4 rounded-lg border-4 border-purple-500 shadow-lg">
              <h3 className="text-xl font-bold mb-2">Recurring Payments</h3>
              <p className="text-sm">
                Set up automatic billing and subscriptions with ease, maximizing your revenue streams.
              </p>
            </div>
            <div className="bg-gray-700 p-4 rounded-lg border-4 border-purple-500 shadow-lg">
              <h3 className="text-xl font-bold mb-2">Real-Time Tracking</h3>
              <p className="text-sm">
                Monitor payments as they happen, keeping you up-to-date with live transaction data.
              </p>
            </div>
          </div>
        </section>

        {/* Get Started Section */}
        <section className="my-8">
          <h2 className="text-4xl font-semibold mb-4">Get Started Today!</h2>
          <div className="bg-gray-800 bg-opacity-80 shadow-lg rounded-lg p-6 mb-4 border-4 border-purple-500">
            <p className="text-lg mb-4">
              Sign up now to experience the power of PaySphere! Our intuitive platform is designed to meet your payment needs seamlessly. 
              Let's build a secure and efficient payment environment together.
            </p>
            <a href="/finance/contact" className="bg-purple-600 text-white px-4 py-2 rounded-lg font-bold hover:bg-purple-400 transition">
              Sign Up Now
            </a>
          </div>
        </section>
      </main>
    </div>
  );
};

export default FinanceHome;
