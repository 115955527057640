import React from 'react';
import RealEstateNavBar from './RealEstateNavBar';

const Testimonials = () => {
  const testimonials = [
    {
      quote: "Thanks to NestQuest, I found my dream home! Their team guided me through every step and made the process so easy and enjoyable. From the moment I reached out to them, I felt valued and supported. They listened to my needs and preferences, and they were always available to answer my questions, no matter how small. Their expertise in the market truly shone through.",
      name: "Sarah Johnson",
      location: "Los Angeles, CA",
    },
    {
      quote: "The service I received from NestQuest was exceptional. They understood my needs and delivered beyond my expectations! I was particularly impressed with how they tailored their approach to suit my unique situation. The agent was knowledgeable, friendly, and professional, making what could have been a stressful process feel seamless and straightforward. I can't recommend them highly enough!",
      name: "Mark Thompson",
      location: "New York, NY",
    },
    {
      quote: "NestQuest helped me sell my house quickly and at a great price. Their expertise in the market is unmatched. From the initial consultation to the final closing, I felt like I was in good hands. They provided invaluable advice on staging my home and setting the right price. Thanks to their efforts, I received multiple offers within just a few days!",
      name: "Linda Garcia",
      location: "Austin, TX",
    },
    {
      quote: "As a first-time buyer, I was nervous about the process, but the team at NestQuest made everything straightforward and stress-free. They took the time to educate me on the buying process and explained all the necessary steps. Their patience and understanding made me feel comfortable, and I ended up finding the perfect place that exceeded all my expectations.",
      name: "James Lee",
      location: "Seattle, WA",
    },
    {
      quote: "I highly recommend NestQuest! Their professionalism and dedication to client satisfaction really set them apart. Throughout the entire buying process, I felt like I was their top priority. They provided detailed information about the properties, the neighborhoods, and what to expect as a new homeowner. Their commitment to excellence truly shines through in everything they do.",
      name: "Emily Davis",
      location: "Chicago, IL",
    },
    {
      quote: "From start to finish, my experience with NestQuest was fantastic. They truly care about their clients and work tirelessly to meet their needs. The attention to detail, quick responses, and genuine kindness from my agent made the experience a pleasure. I felt confident in my decisions, knowing I had the right team supporting me every step of the way.",
      name: "Michael Brown",
      location: "San Francisco, CA",
    },
  ];

  return (
    <div className="bg-[#FDF0D5] text-gray-900 font-montserrat"> {/* Creamy Beige background */}
      <RealEstateNavBar />
      
      <section className="py-16 px-8 md:px-16">
        <h2 className="text-4xl font-playfair mb-6 text-center text-[#A12B2F]">Client Testimonials</h2> {/* Deep Crimson */}
        
        <p className="text-gray-700 text-lg text-center max-w-3xl mx-auto mb-8">
          At NestQuest, our clients' satisfaction is our top priority. We strive to provide exceptional service and guidance, helping our clients navigate the real estate market with confidence. Here’s what some of our happy clients have to say about their experiences with us:
        </p>

        <div className="space-y-8"> {/* Space between testimonials */}
          {testimonials.map((testimonial, index) => (
            <div key={index} className="bg-white rounded-lg shadow-lg p-6">
              <p className="italic text-gray-600 mb-4">"{testimonial.quote}"</p>
              <p className="font-semibold text-[#C44536]">{testimonial.name}</p> {/* Brick Red for names */}
              <p className="text-gray-500">{testimonial.location}</p>
            </div>
          ))}
        </div>

        <div className="mt-10 text-center">
          <h3 className="text-2xl font-playfair mb-4 text-[#A12B2F]">Join Our Community!</h3>
          <p className="text-lg mb-6">Experience the NestQuest difference for yourself. Contact us today to get started on your real estate journey!</p>
          <a href="/realestate/contact" className="bg-[#A12B2F] text-[#FDF0D5] px-8 py-3 rounded-lg font-medium hover:bg-[#C44536] transition"> {/* Brick Red hover */}
            Get in Touch
          </a>
        </div>
      </section>
    </div>
  );
};

export default Testimonials;
