// src/pages/Healthcare/HealthcareNavBar.js

import React from "react";
import { Link } from "react-router-dom";

const HealthcareNavBar = () => (
  <nav className="bg-light-blue-400 text-navy-blue p-4 flex justify-center space-x-6 shadow-lg">
    <Link 
      to="/healthcare" 
      className="hover:underline text-navy-blue font-semibold hover:text-yellow-400 transition-colors duration-200"
    >
      Home
    </Link>
    <Link 
      to="/healthcare/services" 
      className="hover:underline text-navy-blue font-semibold hover:text-yellow-400 transition-colors duration-200"
    >
      Services
    </Link>
    <Link 
      to="/healthcare/about" 
      className="hover:underline text-navy-blue font-semibold hover:text-yellow-400 transition-colors duration-200"
    >
      About
    </Link>
    <Link 
      to="/healthcare/contact" 
      className="hover:underline text-navy-blue font-semibold hover:text-yellow-400 transition-colors duration-200"
    >
      Contact
    </Link>
  </nav>
);

export default HealthcareNavBar;
