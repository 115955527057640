import React, { useState } from "react";
import { Link } from "react-router-dom";
import { FaBars, FaTimes } from "react-icons/fa";

const FurnitureNavBar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => setIsOpen(!isOpen);

  return (
    <nav 
      className="bg-gray-800 text-gray-100 p-4 flex flex-col items-center md:flex-row md:justify-between" 
      style={{ fontFamily: 'Cormorant Garamond, serif' }}
    >
      {/* Brand Name */}
      <div className="text-2xl font-bold text-yellow-400 mb-4 md:mb-0">
        TimberWorks Artisan Woodcraft
      </div>
      
      {/* Hamburger Icon */}
      <div className="md:hidden absolute right-4 top-4" onClick={toggleMenu}>
        {isOpen ? <FaTimes className="text-2xl" /> : <FaBars className="text-2xl" />}
      </div>
      
      {/* Navigation Links */}
      <div 
        className={`flex flex-col items-center md:flex-row space-y-4 md:space-y-0 md:space-x-6 ${
          isOpen ? "flex" : "hidden"
        } md:flex`}
      >
        <Link to="/furniture" className="hover:text-yellow-400 transition">Home</Link>
        <Link to="/furniture/services" className="hover:text-yellow-400 transition">Services</Link>
        <Link to="/furniture/about" className="hover:text-yellow-400 transition">About</Link>
        <Link to="/furniture/contact" className="hover:text-yellow-400 transition">Contact</Link>
      </div>
    </nav>
  );
};

export default FurnitureNavBar;
