import React from 'react';
import ECommerceNavBar from './ECommerceNavBar';

const ECommerceContact = () => {
  return (
    <div className="bg-slate-900 text-white min-h-screen px-6 font-oswald">
      <ECommerceNavBar />

      {/* Header */}
      <header className="text-center mb-12">
        <h1 className="text-5xl font-extrabold text-yellow-500 py-10 font-anton">Get in Touch</h1>
        <p className="text-gray-300 mt-4 max-w-2xl mx-auto">
          Questions? Collaborations? Just want to say hello? Drop us a message and let’s connect.
        </p>
      </header>

      {/* Contact Form and Info */}
      <section className="container mx-auto grid grid-cols-1 md:grid-cols-2 gap-12">
        
        {/* Contact Form */}
        <div className="bg-gray-800 p-8 rounded-lg shadow-lg">
          <h2 className="text-3xl font-bold text-pink-600 mb-6 font-anton">Send Us a Message</h2>
          <form>
            <div className="mb-6">
              <label htmlFor="name" className="block text-sm font-medium text-gray-300 font-oswald">Your Name</label>
              <input
                type="text"
                id="name"
                className="w-full mt-2 p-3 rounded bg-slate-700 text-white focus:ring-pink-600"
                placeholder="John Doe"
              />
            </div>
            <div className="mb-6">
              <label htmlFor="email" className="block text-sm font-medium text-gray-300 font-oswald">Your Email</label>
              <input
                type="email"
                id="email"
                className="w-full mt-2 p-3 rounded bg-slate-700 text-white focus:ring-pink-600"
                placeholder="you@example.com"
              />
            </div>
            <div className="mb-6">
              <label htmlFor="message" className="block text-sm font-medium text-gray-300 font-oswald">Your Message</label>
              <textarea
                id="message"
                rows="4"
                className="w-full mt-2 p-3 rounded bg-slate-700 text-white focus:ring-pink-600"
                placeholder="Write your message here..."
              />
            </div>
            <button type="submit" className="w-full bg-blue-700 text-white py-3 rounded-lg font-semibold hover:bg-blue-800 transition font-anton">
              Send Message
            </button>
          </form>
        </div>

        {/* Contact Information */}
        <div className="text-gray-300 space-y-8 font-oswald">
          <div>
            <h2 className="text-3xl font-bold text-yellow-500 font-anton">Contact Information</h2>
            <p className="mt-2">We're here to help and answer any question you might have. We look forward to hearing from you!</p>
          </div>

          <div>
            <h3 className="text-xl font-semibold text-pink-600 font-anton">Email Us</h3>
            <p className="mt-2">contact@grafstreetwear.com</p>
          </div>

          <div>
            <h3 className="text-xl font-semibold text-blue-700 font-anton">Visit Us</h3>
            <p className="mt-2">123 Streetwear Lane, Urban City, ST 45678</p>
          </div>

          <div>
            <h3 className="text-xl font-semibold text-yellow-500 font-anton">Follow Us</h3>
            <div className="flex space-x-6 mt-2">
              <a href="#" className="text-gray-300 hover:text-pink-600 transition font-oswald">Instagram</a>
              <a href="#" className="text-gray-300 hover:text-blue-700 transition font-oswald">Facebook</a>
              <a href="#" className="text-gray-300 hover:text-yellow-500 transition font-oswald">Twitter</a>
            </div>
          </div>
        </div>
      </section>

      {/* Map Section */}
      <section className="mt-16">
        <iframe
          title="Graf Streetwear Location"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3151.8354345093745!2d144.95373511565353!3d-37.817209742105224!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6ad642af0f11fd81%3A0xf57708c5e5d4d5e6!2sFederation%20Square!5e0!3m2!1sen!2sus!4v1637916375307!5m2!1sen!2sus"
          width="100%"
          height="400"
          className="rounded-lg shadow-lg"
          allowFullScreen=""
          loading="lazy"
        ></iframe>
      </section>
    </div>
  );
};

export default ECommerceContact;
