import React from 'react';
import { motion } from 'framer-motion';
import HealthcareNavBar from './HealthcareNavBar';
import dentalImage from '../../assets/healthcare/dentist.jpg';
import smileImage from '../../assets/healthcare/smile.jpg';

const HealthcareHome = () => {
  // Animation Variants
  const cardVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 }
  };

  return (
    <div className="bg-gray-50 text-gray-900 font-lora">
      <HealthcareNavBar />

      {/* Hero Section with Contact Form */}
      <section
        className="relative bg-cover bg-center h-screen"
        style={{ backgroundImage: `url(${dentalImage})`, zIndex: 10 }}
      >
        <div className="absolute inset-0 bg-blue-800 opacity-40 z-20"></div>

        {/* Hero Content Wrapper with responsive padding-top */}
        <div className="relative z-30 flex flex-col md:flex-row items-center justify-center h-full text-center text-white px-4 sm:px-6 md:px-16 pt-32 sm:pt-20 md:pt-0"> {/* Adjusted padding */}
          <div className="md:w-1/2 mb-12 sm:mb-16 md:mb-0">
            <h1 className="text-4xl sm:text-5xl md:text-7xl font-bold mb-4">Smiles Dental</h1>
            <h2 className="text-lg sm:text-xl md:text-2xl font-bold mb-4">Your Smile, Our Priority</h2>
            <p className="text-base sm:text-lg md:text-xl mb-8">Providing compassionate dental care with professional expertise.</p>
          </div>
          <div className="bg-white bg-opacity-90 text-gray-900 p-6 sm:p-8 rounded-lg shadow-lg md:ml-8 sm:w-full md:w-1/3">
            <h2 className="text-xl sm:text-2xl font-bold mb-4 text-blue-800">Emergency? Book Now</h2>
            <form>
              <div className="mb-4">
                <label className="block text-gray-700 font-medium mb-1" htmlFor="name">Name</label>
                <input type="text" id="name" className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring focus:ring-blue-300" placeholder="Enter your name" />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 font-medium mb-1" htmlFor="phone">Phone</label>
                <input type="tel" id="phone" className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring focus:ring-blue-300" placeholder="Enter your phone number" />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 font-medium mb-1" htmlFor="date">Preferred Date</label>
                <input type="date" id="date" className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring focus:ring-blue-300" />
              </div>
              <button type="submit" className="w-full bg-yellow-400 text-gray-900 px-6 py-3 rounded-lg font-medium hover:bg-yellow-500 transition">
                Book an Appointment
              </button>
            </form>
          </div>
        </div>
      </section>

      {/* Stats Section */}
      <section className="py-12 sm:py-16 px-4 sm:px-8 md:px-16 bg-blue-50 text-center">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          <div className="flex flex-col items-center">
            <h3 className="text-4xl sm:text-5xl font-bold text-blue-800">15Y</h3>
            <p className="text-base sm:text-lg text-gray-700 mt-2">In the Industry</p>
          </div>
          <div className="flex flex-col items-center">
            <h3 className="text-4xl sm:text-5xl font-bold text-blue-800">3K+</h3>
            <p className="text-base sm:text-lg text-gray-700 mt-2">Clients Served</p>
          </div>
          <div className="flex flex-col items-center">
            <h3 className="text-4xl sm:text-5xl font-bold text-blue-800">10+</h3>
            <p className="text-base sm:text-lg text-gray-700 mt-2">Dentists on Call</p>
          </div>
        </div>
      </section>

      {/* About Us */}
      <section className="py-12 sm:py-16 px-4 sm:px-8 md:px-16 bg-white">
        <h2 className="text-2xl sm:text-3xl font-bold mb-6 text-center">Committed Service, Exceptional Care</h2>
        <p className="max-w-3xl mx-auto text-gray-700 text-base sm:text-lg text-center leading-relaxed">
          At Smiles Dental, we believe in delivering exceptional service and extraordinary results. Our dedicated team of professionals is committed to providing compassionate care with a focus on excellence, ensuring that each patient leaves with a brighter, healthier smile.
        </p>
      </section>

      {/* Services Offered */}
      <section className="py-12 sm:py-16 px-4 sm:px-8 md:px-16 bg-gray-100">
        <h2 className="text-2xl sm:text-3xl font-bold mb-6 text-center">Our Services</h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {[
            { title: "Cleanings & Exams", description: "Routine care to maintain a healthy smile." },
            { title: "Cosmetic Dentistry", description: "Enhance the beauty of your smile with whitening and more." },
            { title: "Emergency Care", description: "Immediate support for urgent dental needs." },
            { title: "Orthodontics", description: "Customized braces and aligners for a perfect smile." },
            { title: "Restorative Dentistry", description: "Restoring full function with fillings, crowns, and implants." },
            { title: "Pediatric Dentistry", description: "Gentle care for our youngest patients." }
          ].map((service, index) => (
            <motion.div
              key={index}
              className="bg-white rounded-lg shadow-lg p-6"
              variants={cardVariants}
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true, amount: 0.3 }}
              transition={{ duration: 0.6, delay: index * 0.1 }}
            >
              <h3 className="text-lg sm:text-xl font-semibold mb-2">{service.title}</h3>
              <p className="text-gray-600 text-sm sm:text-base">{service.description}</p>
            </motion.div>
          ))}
        </div>
      </section>

      {/* Featured Treatment */}
      <section className="py-12 sm:py-16 px-4 sm:px-8 md:px-16 bg-white">
        <h2 className="text-2xl sm:text-3xl font-bold mb-6 text-center">Featured Treatment: Teeth Whitening</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 items-center">
          <img src={smileImage} alt="Teeth Whitening" className="rounded-lg shadow-lg w-full h-auto z-10" />
          <div>
            <p className="text-gray-700 text-base sm:text-lg">
              Reveal a brighter, more radiant smile with our professional teeth whitening treatment. This safe, non-invasive procedure is designed to lift years of stains caused by coffee, tea, wine, and everyday wear, giving you a luminous smile that boosts your confidence.
            </p>
            <p className="text-gray-700 text-base sm:text-lg mt-4">
              Our in-office whitening treatment uses advanced, professional-grade whitening agents that penetrate deep into the enamel, achieving results that are both effective and long-lasting. Unlike over-the-counter products, our treatment is customized to your unique needs, ensuring an even shade and minimizing sensitivity.
            </p>
            <ul className="list-disc list-inside mt-6 text-gray-600 text-sm sm:text-base">
              <li><span className="font-semibold text-blue-800">Quick and Painless:</span> Enjoy noticeable results in a single session, with minimal to no discomfort.</li>
              <li><span className="font-semibold text-blue-800">Customized Care:</span> Our whitening solution is tailored to your specific shade and sensitivity levels for optimal comfort and results.</li>
              <li><span className="font-semibold text-blue-800">Professional-Grade Brightness:</span> Achieve a level of brightness that is not possible with store-bought products.</li>
              <li><span className="font-semibold text-blue-800">Safe for Your Enamel:</span> Our whitening agents are formulated to brighten without compromising the health of your enamel.</li>
            </ul>
            <p className="text-gray-700 text-base sm:text-lg mt-4">
              Whether you're preparing for a special event or simply want to enhance your everyday smile, our teeth whitening treatment provides a safe, effective solution. Experience the confidence of a rejuvenated smile today—schedule your consultation and let us help you achieve the brilliance you deserve.
            </p>
          </div>
        </div>
      </section>

      {/* Patient Testimonials */}
      <section className="py-12 sm:py-16 px-4 sm:px-8 md:px-16 bg-gray-100">
        <h2 className="text-2xl sm:text-3xl font-bold mb-6 text-center">What Our Patients Say</h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {[
            { 
              text: "Smiles Dental has redefined dental visits for me. I came in with years of anxiety and hesitation, but from the moment I walked in, the staff’s warm welcome put me at ease. Dr. Lee's patient care and attention to detail were incredible. I finally feel comfortable coming in for my cleanings!",
              name: "Amy S.", 
              year: "Patient since 2018" 
            },
            { 
              text: "I had been searching for a dental clinic that treats you as more than just another patient, and Smiles Dental does exactly that. The team genuinely cares and takes time to make sure you’re comfortable. My cosmetic dentistry results were beyond expectations—I can’t stop smiling!",
              name: "Michael B.", 
              year: "Patient since 2021" 
            },
            { 
              text: "Our whole family goes to Smiles Dental, and it has been such a positive experience for all of us. My kids actually look forward to their appointments, which is saying a lot! The team goes above and beyond to make every visit feel welcoming, friendly, and comfortable.",
              name: "Lisa T.", 
              year: "Patient since 2017" 
            }
          ].map((testimonial, index) => (
            <motion.div
              key={index}
              className="bg-white rounded-lg p-6 shadow-lg"
              initial={{ opacity: 0, y: 50 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true, amount: 0.5 }}
              transition={{ type: "spring", stiffness: 100, damping: 10, delay: index * 0.2 }}
            >
              <p className="italic text-gray-700 text-sm sm:text-base">"{testimonial.text}"</p>
              <p className="mt-4 text-right font-bold text-sm sm:text-base">- {testimonial.name}</p>
              <p className="text-right text-gray-500 text-sm sm:text-base">{testimonial.year}</p>
            </motion.div>
          ))}
        </div>
      </section>

      {/* Contact CTA */}
      <section className="py-12 sm:py-16 px-4 sm:px-8 md:px-16 bg-blue-800 text-white text-center">
        <h2 className="text-2xl sm:text-3xl font-bold mb-4">Ready for a Brighter Smile?</h2>
        <p className="text-base sm:text-lg mb-6">Reach out today to schedule an appointment or learn more about our services.</p>
        <a href="mailto:contact@smilesdental.com" className="bg-yellow-400 text-gray-900 px-6 py-3 rounded-lg font-medium hover:bg-yellow-500 transition">
          Contact Us
        </a>
      </section>
    </div>
  );
};

export default HealthcareHome;
