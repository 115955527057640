import React from 'react';
import RealEstateNavBar from './RealEstateNavBar';

const RealEstateContact = () => {
  return (
    <div className="bg-[#FDF0D5] text-gray-900 font-montserrat"> {/* Creamy Beige background */}
      <RealEstateNavBar />
      
      <section className="py-16 px-8 md:px-16">
        <h2 className="text-4xl font-playfair mb-6 text-center text-[#A12B2F]">Contact Us</h2> {/* Deep Crimson */}
        
        <p className="text-gray-700 text-lg text-center max-w-3xl mx-auto mb-8">
          We would love to hear from you! Whether you have questions, need assistance, or want to schedule a consultation, our dedicated team is here to help you every step of the way.
        </p>

        <div className="flex flex-col md:flex-row justify-between mt-12">
          <div className="flex-1 mr-4 mb-6">
            <h3 className="text-2xl font-playfair mb-4 text-[#C44536]">Meet Our Agents</h3> {/* Brick Red for headings */}
            <div className="bg-white rounded-lg shadow-lg p-6 mb-4">
              <h4 className="text-xl font-semibold">Jane Doe</h4>
              <p className="text-gray-700">Senior Real Estate Agent</p>
              <p className="text-gray-600">With over 10 years of experience, Jane specializes in luxury properties and client satisfaction.</p>
              <p className="text-gray-600">Email: jane.doe@nestquest.com</p>
              <p className="text-gray-600">Phone: (123) 456-7890</p>
            </div>
            <div className="bg-white rounded-lg shadow-lg p-6">
              <h4 className="text-xl font-semibold">John Smith</h4>
              <p className="text-gray-700">Real Estate Consultant</p>
              <p className="text-gray-600">John has a passion for helping first-time buyers find their dream homes.</p>
              <p className="text-gray-600">Email: john.smith@nestquest.com</p>
              <p className="text-gray-600">Phone: (123) 456-7891</p>
            </div>
          </div>

          <div className="flex-1 ml-4">
            <h3 className="text-2xl font-playfair mb-4 text-[#C44536]">Get in Touch</h3> {/* Brick Red for headings */}
            <form className="bg-white rounded-lg shadow-lg p-6 space-y-4">
              <input 
                type="text" 
                placeholder="Your Name" 
                className="w-full p-3 rounded border border-gray-300" 
                required 
              />
              <input 
                type="email" 
                placeholder="Your Email" 
                className="w-full p-3 rounded border border-gray-300" 
                required 
              />
              <textarea 
                placeholder="Your Message" 
                className="w-full p-3 rounded border border-gray-300" 
                rows="4" 
                required 
              ></textarea>
              <button 
                type="submit" 
                className="bg-[#A12B2F] text-[#FDF0D5] px-8 py-3 rounded-lg font-medium hover:bg-[#C44536] transition w-full">
                Send Message
              </button>
            </form>
          </div>
        </div>
      </section>
    </div>
  );
};

export default RealEstateContact;
