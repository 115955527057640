import React from "react";
import { Link } from "react-router-dom";

const LogisticsNavBar = () => (
  <nav
    style={{ fontFamily: 'lobster, sans-serif' }}
    className="bg-gray-800 text-gray-200 p-4 flex justify-center space-x-6"
  >
    <Link to="/logistics" className="hover:text-orange-600 transition">Home</Link>
    <Link to="/logistics/services" className="hover:text-orange-600 transition">Services</Link>
    <Link to="/logistics/about" className="hover:text-orange-600 transition">About</Link>
    <Link to="/logistics/contact" className="hover:text-orange-600 transition">Contact</Link>
  </nav>
);

export default LogisticsNavBar;
