import React from "react";
import FinanceNavBar from "./FinanceNavBar"; // Navbar component
import image from "../../assets/finance/ps1.webp";
import image1 from "../../assets/finance/ps2.webp";
import image2 from "../../assets/finance/ps3.webp";

const FinancePricing = () => {
  return (
    <div className="min-h-screen bg-black text-white font-play">
      <FinanceNavBar />

      {/* Page Heading */}
      <div className="text-center my-8">
        <h1 className="text-6xl font-extrabold text-[#FFD700] mb-4">Pricing Plans</h1>
        <p className="text-lg mb-8">Choose a plan that suits your business needs.</p>
      </div>

      {/* Pricing Options */}
      <main className="p-6 max-w-screen-lg mx-auto grid grid-cols-1 md:grid-cols-3 gap-8">
        
        {/* Basic Plan */}
        <section className="relative border-4 border-purple-600 rounded-lg bg-gray-800 p-6 flex flex-col items-center">
          <img
            src={image} 
            alt="Basic Plan"
            className="w-full h-40 object-cover rounded-t-lg mb-4"
          />
          <h2 className="text-4xl font-semibold mb-4">Basic Plan</h2>
          <p className="text-lg mb-4">$19/month</p>
          <ul className="text-lg mb-4 flex-grow">
            <li>✔ Secure Payments</li>
            <li>✔ Email Support</li>
            <li>✔ Basic Analytics</li>
          </ul>
          <a href="/finance/contact" className="bg-purple-600 text-white px-4 py-2 text-center rounded-lg font-bold hover:bg-purple-400 transition self-stretch">
            Sign Up
          </a>
        </section>

        {/* Pro Plan */}
        <section className="relative border-4 border-purple-600 rounded-lg bg-gray-800 p-6 flex flex-col items-center">
          <img
            src={image1} 
            alt="Pro Plan"
            className="w-full h-40 object-cover rounded-t-lg mb-4"
          />
          <h2 className="text-4xl font-semibold mb-4">Pro Plan</h2>
          <p className="text-lg mb-4">$49/month</p>
          <ul className="text-lg mb-4 flex-grow">
            <li>✔ Everything in Basic</li>
            <li>✔ Multi-Currency Support</li>
            <li>✔ Advanced Analytics</li>
            <li>✔ Phone Support</li>
          </ul>
          <a href="/finance/contact" className="bg-purple-600 text-center text-white px-4 py-2 rounded-lg font-bold hover:bg-purple-400 transition self-stretch">
            Sign Up
          </a>
        </section>

        {/* Enterprise Plan */}
        <section className="relative border-4 border-purple-600 rounded-lg bg-gray-800 p-6 flex flex-col items-center">
          <img
            src={image2} 
            alt="Enterprise Plan"
            className="w-full h-40 object-cover rounded-t-lg mb-4"
          />
          <h2 className="text-4xl font-semibold mb-4">Enterprise Plan</h2>
          <p className="text-lg mb-4">Contact for pricing</p>
          <ul className="text-lg mb-4 flex-grow">
            <li>✔ Everything in Pro</li>
            <li>✔ Customized Solutions</li>
            <li>✔ Dedicated Account Manager</li>
            <li>✔ 24/7 Priority Support</li>
          </ul>
          <a href="/finance/contact" className="bg-purple-600 text-white text-center px-4 py-2 rounded-lg font-bold hover:bg-purple-400 transition self-stretch">
            Contact Us
          </a>
        </section>
      </main>
    </div>
  );
};

export default FinancePricing;
